@value greyBackground from 'assets/styles/abstracts/colors.scss';

@font-face {
  font-family: 'RobotoLight';
  src: url('assets/fonts/Roboto-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoRegular';
  src: url('assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoLightItalic';
  src: url('assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoBoldItalic';
  src: url('assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoMediumItalic';
  src: url('assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoItalic';
  src: url('assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body{
  margin: 0;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  position: relative;
}

.pageContent{
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  overflow: hidden;
  padding: 50px 0;
  background: greyBackground;
  position: relative;

  @media (max-width: 600px){
    padding: 30px 0;
  }
}
