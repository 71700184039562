@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green2 from '../../../../assets/styles/abstracts/colors.scss';
@value purple from '../../../../assets/styles/abstracts/colors.scss';
@value orange from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  max-width: 100%;
  width: fit-content;
  border: 1px solid grey3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px 10px 5px;
}

.block{
  display: flex;
  align-items: center;

  &:not(:last-child){
    margin-bottom: 14px;
  }
}

.title{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: grey1;
  width: 305px;
  margin-right: 18px;
  flex-shrink: 0;
  margin-bottom: 10px;
}

.labels{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.label{
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background: whiteBasic;
  border: 2px solid grey2;
  box-sizing: border-box;
  border-radius: 15px;
  height: 26px;
  padding: 5px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: grey2;
  cursor: pointer;
  transition: .3s ease;
  position: relative;
  margin-bottom: 10px;

  &:not(:last-child){
    margin-right: 13px;
  }

  &.square{
    border-radius: 0;
    cursor: auto;
  }

  &.navyTheme{
    border: 2px solid navy;
    color: navy;

    &:hover{
      background: navy;
      color: whiteBasic;

      div{
        opacity: 1;
      }
    }
  }

  &.greenTheme{
    border: 2px solid green2;
    color: green2;

    &:hover{
      background: green2;
      color: whiteBasic;

      div{
        opacity: 1;
      }
    }
  }

  &.purpleTheme{
    border: 2px solid purple;
    color: purple;

    &:hover{
      background: purple;
      color: whiteBasic;

      div{
        opacity: 1;
      }
    }
  }

  &.orangeTheme{
    border: 2px solid orange;
    color: orange;
  }

  &.greyTheme{
    border: 2px solid grey2;
    color: grey2;
  }
}
