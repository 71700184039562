@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.item{
  flex-shrink: 0;
  margin: 0;
  width: 50px;
  height: 50px;
  background: whiteBasic;
  border: 2px solid navy;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: navy;
  position: relative;
  cursor: pointer;
  transition: .3s ease;
  z-index: 4;

  &.moveBottom{
    transform: translateY(10px);
  }

  &.themeGrey{
    background: grey4;
  }

  &.marginsBig{
    margin: 0 25px;
  }

  &.marginsMiddle{
    margin: 0 15px;
  }

  &.marginRight{
    margin-right: 30px;
  }

  &.marginLeft{
    margin-left: 30px;
  }

  &.marginRightShort{
    margin-right: 15px;
  }

  &.marginLeftShort{
    margin-left: 15px;
  }

  &.plus{
    .plusIcon{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text{
      font-size: 20px;
      line-height: 23px;
    }

    &:hover{
      background: blue;
      color: whiteBasic;
    }
  }

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.plusIcon{
  display: none;
}

.arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translateX(calc(100% + 2px));
}
