@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 500px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 340px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 230px;
  }
}

.infoWrapper{
  display: flex;
  align-items: stretch;
  justify-content: center;
  max-width: 100%;
  padding: 53px 10px 50px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 50px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.25);
  }
}

.illustration{
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whiteBasic;
  border: 1px solid grey3;
  box-sizing: border-box;
  border-radius: 20px;
  margin-right: 50px;
  width: 515px;
  position: relative;
}

.illustrationImage{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image{
  max-width: 510px;
  max-height: 100%;
}

.infoBlockContainer{
  background: whiteBasic;
  border: 1px solid grey3;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 20px;
  width: 515px;
}

.topContent{
  padding: 10px 0 30px;
}

.cardsWrapper{
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 370px;
  flex-shrink: 0;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;

  &:nth-child(1),
  &:nth-child(3) {
    max-width: 145px
  }

  &:nth-child(2){
    max-width: 30px;
    height: 150px;

    .mainContent{
      height: 100%;

      .infoBlockText{
        transform: rotate(-90deg);
        width: 110px;
      }
    }
  }
}

.mainContent{
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 45px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.05em;
  color: navy;
  border-radius: 2.5px;
  cursor: pointer;
  transition: .3s ease;

  p, ul, li{
    margin: 0;
  }

  ul{
    padding-left: 14px;
  }

  li{
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }

  li::marker{
    color: navy;
  }

  &:hover{
    background: blue1;
    color: whiteBasic;

    div{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.infoBlockText{
  &:not(:first-child){
    margin-top: 10px;
  }
}

.yellowColored{
  .mainContent{
    border: 2px solid navy;
    background: yellowBasic;

    &:hover{
      background: blue1;
      color: whiteBasic;
    }

    &.whiteColored{
      cursor: auto;
      flex: 1;
      max-height: 75px;
      color: navy;
      background: whiteBasic;
      text-align: left;

      &:hover {
        background: whiteBasic;
        color: navy;
      }
    }
  }
}

.blueColored{
  .mainContent{
    border: 2px solid navy;
    background: navy;
    color: whiteBasic;

    &:hover{
      background: blue1;
      color: whiteBasic;
    }

    &.whiteColored{
      cursor: auto;
      flex: 1;
      max-height: 75px;
      color: navy;
      background: whiteBasic;
      text-align: left;

      li{
        &:not(:last-child){
          margin-bottom: 0;
        }
      }

      &:hover {
        background: whiteBasic;
        color: navy;
      }
    }
  }
}

.bottomContent{}

.tableTitle{
  margin-bottom: 20px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: navy;
}

.table{
  width: 100%;
}

.row{
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid grey4;
  background: whiteBasic;

  &.greyColor{
    background: grey5;
  }

  &.blueColor{
    background: blue1;
  }

  &.yellowColor{
    background: yellowBasic;
  }

  &.bold{
    .cell2{
      font-family: RobotoMedium, Arial, sans-serif;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      color: navy;
      cursor: pointer;
      position: relative;

      &:hover{
        div{
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}

.cell1{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;
  width: 40px;
  border-right: 1px solid grey4;
}

.cell2{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;
  padding: 3px 5px;
}

.aTrigger{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 5;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.bTrigger{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 38px;
  height: 38px;
  cursor: pointer;
  z-index: 5;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.bTrigger1{
    left: 336px;
    top: 236px;
    transform: translate(0, 0);
  }

  &.bTrigger2{
    left: 260px;
    top: 326px;
    transform: translate(0, 0);
  }

  &.bTrigger3{
    left: 149px;
    top: 169px;
    transform: translate(0, 0);
  }

  &.bTrigger4{
    left: 263px;
    top: 137px;
    transform: translate(0, 0);
  }
}

.cTrigger{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 5;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.cTrigger1{
    left: 96px;
    top: 141px;
    transform: translate(0, 0);
  }

  &.cTrigger2{
    left: 285px;
    top: 78px;
    transform: translate(0, 0);
  }

  &.cTrigger3{
    left: 323px;
    top: 93px;
    transform: translate(0, 0);
  }

  &.cTrigger4{
    left: 402px;
    top: 198px;
    transform: translate(0, 0);
  }

  &.cTrigger5{
    left: 406px;
    top: 239px;
    transform: translate(0, 0);
  }

  &.cTrigger6{
    left: 402px;
    top: 280px;
    transform: translate(0, 0);
  }

  &.cTrigger7{
    left: 325px;
    top: 376px;
    transform: translate(0, 0);
  }

  &.cTrigger8{
    left: 288px;
    top: 394px;
    transform: translate(0, 0);
  }

  &.cTrigger9{
    left: 126px;
    top: 364px;
    transform: translate(0, 0);
  }

  &.cTrigger10{
    left: 105px;
    top: 339px;
    transform: translate(0, 0);
  }
}

.oneTrigger{
  position: absolute;
  left: 396px;
  top: 127px;
  transform: translate(0, 0);
  width: 31px;
  height: 32px;
  cursor: pointer;
  z-index: 1;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.twoTrigger{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 22px;
  cursor: pointer;
  z-index: 5;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.twoTrigger1{
    left: 165px;
    top: 144px;
    transform: translate(0, 0);
  }

  &.twoTrigger2{
    left: 235px;
    top: 119px;
    transform: translate(0, 0);
  }

  &.twoTrigger3{
    left: 362px;
    top: 195px;
    transform: translate(0, 0);
  }

  &.twoTrigger4{
    left: 253px;
    top: 371px;
    transform: translate(0, 0);
  }

  &.twoTrigger5{
    left: 130px;
    top: 285px;
    transform: translate(0, 0);
  }
}

.threeTrigger{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 16px;
  cursor: pointer;
  z-index: 5;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.threeTrigger1{
    left: 253px;
    top: 80px;
    transform: translate(0, 0);
  }

  &.threeTrigger2{
    left: 430px;
    top: 222px;
    transform: translate(0, 0);
  }

  &.threeTrigger3{
    left: 328px;
    top: 407px;
    transform: translate(0, 0);
  }

  &.threeTrigger4{
    left: 116px;
    top: 371px;
    transform: translate(0, 0);
  }

  &.threeTrigger5{
    left: 141px;
    top: 119px;
    transform: translate(0, 0);
  }
}

.dashedSmTrigger{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  cursor: pointer;
  z-index: 5;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.dashedSmTrigger1{
    left: 420px;
    top: 228px;
    transform: none;
  }

  &.dashedSmTrigger2{
    left: 420px;
    top: 269px;
    transform: none;
  }

  &.dashedSmTrigger3{
    left: 321px;
    top: 402px;
    transform: none;
  }

  &.dashedSmTrigger4{
    left: 282px;
    top: 416px;
    transform: none;
  }

  &.dashedSmTrigger5{
    left: 129px;
    top: 365px;
    transform: none;
  }

  &.dashedSmTrigger6{
    left: 106px;
    top: 331px;
    transform: none;
  }

  &.dashedSmTrigger7{
    left: 317px;
    top: 101px;
    transform: none;
  }
}

.dashedBgTrigger {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 190px;
  height: 190px;
  cursor: pointer;
  z-index: 3;

  &:hover {
    div {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.solidSmTrigger{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 5;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.solidSmTrigger1{
    left: 292px;
    top: 112px;
    transform: none;
  }

  &.solidSmTrigger2{
    left: 296px;
    top: 122px;
    transform: none;
  }

  &.solidSmTrigger3{
    left: 371px;
    top: 222px;
    transform: none;
  }

  &.solidSmTrigger4{
    left: 374px;
    top: 255px;
    transform: none;
  }

  &.solidSmTrigger5{
    left: 371px;
    top: 256px;
    transform: none;
  }

  &.solidSmTrigger6{
    left: 299px;
    top: 354px;
    transform: none;
  }

  &.solidSmTrigger7{
    left: 291px;
    top: 369px;
    transform: none;
  }

  &.solidSmTrigger8{
    left: 257px;
    top: 369px;
    transform: none;
  }

  &.solidSmTrigger9{
    left: 140px;
    top: 332px;
    transform: none;
  }

  &.solidSmTrigger10{
    left: 133px;
    top: 326px;
    transform: none;
  }

  &.solidSmTrigger11{
    left: 115px;
    top: 300px;
    transform: none;
  }

  &.solidSmTrigger12{
    left: 131px;
    top: 169px;
    transform: none;
  }
}

.solidBgTrigger {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 145px;
  height: 145px;
  cursor: pointer;
  z-index: 4;

  &:hover {
    div {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.link{
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
