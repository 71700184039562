@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';

.block{
  width: 100%;
  display: flex;
  align-items: center;

  &.blockLeft{
    flex-direction: row;
    justify-content: flex-end;

    .infoText{
      text-align: right;
      margin-right: 35px;

      &:after{
        content: '';
        width: 40px;
        height: 2px;
        background-color: red;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateX(calc(100% + 10px)) translateY(-50%);
        opacity: 0;
        transition: .3s ease;
      }
    }
  }

  &.blockRight{
    flex-direction: row-reverse;
    justify-content: flex-start;

    .infoText{
      text-align: left;
      margin-left: 35px;

      &:after{
        content: '';
        width: 40px;
        height: 2px;
        background-color: red;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateX(calc(-100% - 10px)) translateY(-50%);
        opacity: 0;
        transition: .3s ease;
      }
    }
  }

  &.blueColor{
    .infoText{
      color: blue;
    }

    .arrows{
      svg{
        path{
          fill: blue;
        }
      }
    }
  }

  &.redColor{
    .infoText{
      color: red;
    }

    .arrows{
      svg{
        path{
          fill: red;
        }
      }
    }
  }

  &.redBlueColor{
    .infoText{
      color: blue;
    }

    .arrows{
      svg{
        path{
          fill: red;
        }
      }
    }
  }

  &:hover{
    .infoText{
      color: grey1;

      &:after{
        opacity: 1;
      }
    }

    .arrows{
      overflow: unset;

      svg{
        path{
          fill: red;
        }
      }
    }
  }
}

.infoText{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: grey5;
  max-width: 166px;
  position: relative;
  transition: .3s ease;
  cursor: pointer;
}

.arrows{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  overflow: hidden;

  svg{
    path{
      fill: grey5;
      transition: .3s ease;
    }
  }
}
