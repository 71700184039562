@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value orange from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  position: relative;
}

.infoWrapper{
  width: 100%;
  margin: 0 auto;
}

.link{
  cursor: pointer;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: blue;
  display: block;

  &:not(:last-child){
    margin-bottom: 20px;
  }
}

.termsLinks{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.termsHeader{
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  margin: 20px 0;
}
