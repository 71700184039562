@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value purple from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.toolTip{
  cursor: text;
  max-width: 250px;
  width: 100vw;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 14px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: whiteBasic;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: grey1;

  &:after{
    content: '';
    width: 40px;
    height: 40px;
    transform: translateY(50%) rotate(45deg) scale(0.7);
    background: whiteBasic;
    flex-shrink: 0;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  }

  .tooltipText{
    background: whiteBasic;
    border-radius: 3px;
    padding: 7px;
    text-align: left;
    margin-bottom: -40px;
    position: relative;
    z-index: 1;
  }

  p, ul, li{
    margin: 0;
  }

  ul{
    padding-left: 24px;
  }

  li::marker{
    color: grey1;
  }

  b{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  &.themeRed{
    transform: translate(-50%, calc(-100% - 22px));
    background: red;
    color: whiteBasic;

    .tooltipText{
      background: red;
    }

    &:after{
      background: red;
    }

    li::marker{
      color: whiteBasic;
    }
  }

  &.themeNavy{
    transform: translate(-50%, calc(-100% - 22px));
    background: navy;
    color: whiteBasic;

    .tooltipText{
      background: navy;
      max-width: 100%;
      white-space: normal;
    }

    &:after{
      background: navy;
    }

    li::marker{
      color: whiteBasic;
    }

    @media (max-width: 768px){
      max-width: 200px !important;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &.themeGreen{
    transform: translate(-50%, calc(-100% - 22px));
    background: green;
    color: whiteBasic;

    .tooltipText{
      background: green;
      max-width: 100%;
      white-space: normal;
    }

    &:after{
      background: green;
    }

    li::marker{
      color: whiteBasic;
    }

    @media (max-width: 768px){
      max-width: 200px !important;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &.themePurple{
    transform: translate(-50%, calc(-100% - 22px));
    background: purple;
    color: whiteBasic;

    .tooltipText{
      background: purple;
      max-width: 100%;
      white-space: normal;
    }

    &:after{
      background: purple;
    }

    li::marker{
      color: whiteBasic;
    }

    @media (max-width: 768px){
      max-width: 200px !important;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &.reversed{
    flex-direction: column-reverse;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 14px));

    &.themeRed{
      transform: translate(-50%, calc(100% + 22px));
    }

    .tooltipText{
      margin-bottom: 0;
      margin-top: -40px;
    }

    &:after{
      transform: translateY(-50%) rotate(45deg) scale(0.7);
    }
  }

  &.cornerPosition{
    left: 0;
    transform: translate(0, calc(-100% - 22px));
  }
}
