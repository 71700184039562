@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value purple from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: greyBackground;
  border: 3px solid grey2;
  border-radius: 10px;
  padding: 10px 10px 20px;

  &.blueColor{
    .button{
      background: navy;
    }
  }

  &.purpleColor{
    .button{
      background: purple;
    }
  }
}

.button{
  min-height: 40px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  white-space: pre-line;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: whiteBasic;
  position: relative;
  z-index: 4;
  cursor: pointer;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.arrow{
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainText{
  flex: 1;
  background: whiteBasic;
  border: 3px solid grey2;
  border-radius: 5px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 4px;
  min-height: 106px;
}

.bottomText{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: grey2;
  margin-top: 12px;
}

.chartWrapper{
  margin-top: 2px;
  width: 100%;
  position: relative;
  background: whiteBasic;
  border: 3px solid grey2;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartText1{
  width: 138px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: red;
  position: absolute;
  top: 10px;
  transform: translateX(-100px);
}

.chartText2{
  width: 154px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: grey1;
  transform: rotate(-90deg) translateY(-70px);
  position: absolute;
  bottom: 55px;
  transform-origin: 0 100%;
}
