@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.toolTip{
  cursor: text;
  max-width: 250px;
  width: 100vw;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 14px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: whiteBasic;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: grey2;

  &.black{
    cursor: text;
    max-width: 250px;
    width: 100vw;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 57px));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: grey1;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-family: Roboto, Arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: yellowBasic;

    &:after{
      content: '';
      width: 40px;
      height: 40px;
      transform: translateY(50%) rotate(45deg);
      background: grey1;
    }

    .tooltipText{
      color: yellowBasic;
      background: grey1;
      padding: 10px 10px 15px;
      text-align: left;
      margin-bottom: -40px;
      position: relative;
      z-index: 1;
    }

    p, ul, li{
      margin: 0;
    }

    ul{
      padding-left: 24px;
    }

    li::marker{
      color: yellowBasic;
    }
  }

  &.moveTop{
    transform: translate(-50%, calc(-100% - 47px));
  }

  &.moveTopLess{
    transform: translate(-50%, calc(-100% - 27px));
  }

  &:after{
    content: '';
    width: 40px;
    height: 40px;
    transform: translateY(50%) rotate(45deg);
    background: whiteBasic;
  }

  /*@media (max-width: 1024px){
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 14px));
    justify-content: flex-start;

    &:after{
      display: none;
    }

    &:before{
      content: '';
      width: 20px;
      height: 20px;
      transform: translateY(-50%) rotate(45deg);
      background: whiteBasic;
    }
  }*/

  /*@media (max-width: 600px){
    max-width: 100% !important;
  }*/

  .tooltipText{
    background: whiteBasic;
    padding: 10px 10px 15px;
    text-align: left;
    margin-bottom: -40px;
    position: relative;
    z-index: 1;

    /*@media (max-width: 1024px){
      padding: 15px 10px 10px;
      margin-bottom: 0px;
      margin-top: -20px;

      ul{
        padding-left: 14px;
      }
    }*/
  }

  p, ul, li{
    margin: 0;
  }

  ul{
    padding-left: 24px;
  }

  li::marker{
    color: grey2;
  }
}
