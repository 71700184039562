@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 390px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 260px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 230px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  padding: 103px 10px 50px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 109px 0 50px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.4);
  }
}

.cardsWrapper{
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infoBlock {
  flex-shrink: 0;
  width: 260px;

  &:not(:last-child){
    margin-right: 75px;
  }
}

.mainContent{
  height: 100%;
  min-height: 400px;
  position: relative;
  width: 100%;
  background: whiteBasic;
  filter: drop-shadow(8px 8px 12px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.header{
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: whiteBasic;
  display: flex;
  align-items: center;
  justify-content: center;
  background: navy;
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
}

.content{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentText{
  cursor: pointer;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.05em;
  color: navy;
  padding: 6px 10px;
  border: 4px solid navy;
  box-sizing: border-box;
  max-width: 200px;
  width: 100%;
  transition: .3s ease;

  &:not(:last-child){
    margin-bottom: 16px;
  }

  &.contentTextSecond{
    transform: translateY(2px);
  }

  &:hover{
    background: navy;
    color: whiteBasic;
  }
}

.contentHint{
  font-family: RobotoItalic, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: red;

  &:not(:last-child){
    margin-bottom: 16px;
  }

  &.imageHint{
    font-family: RobotoMedium, Arial, sans-serif;
  }
}

.contentImage{
  padding: 6px 10px;
  border: 4px solid navy;
  max-width: 200px;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child){
    margin-bottom: 20px;
  }
}

.image{
  width: 100%;
  height: auto;
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
