@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../assets/styles/abstracts/colors.scss';
@value red from '../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.25));
  background: whiteBasic;

  @media (max-width: 1024px) {
    margin: 30px auto 0;
  }

  @media (max-width: 768px){
    margin: 0 auto;
  }
}

.top{
  padding: 50px 50px 20px;
  border-bottom: 1px solid grey4;

  @media (max-width: 768px){
    padding: 20px 16px;
  }
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: navy;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  margin-top: 6px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: grey3;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
  }
}

.bottom{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: grey1;
  padding: 40px 50px 50px;

  @media (max-width: 768px){
    padding: 20px 16px;
  }
}

.section1Heading{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: grey2;
  margin-bottom: 20px;

  &.bottomTitleBlue{
    color: navy;
  }

  &.bottomTitleCenter{
    padding: 40px;

    @media (max-width: 768px){
      padding: 0;
    }
  }

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
    padding: 0;
  }
}

.blocks{
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .block{
    flex: 1;
    max-width: calc(50% - 20px);

    &.mainTextBig{
      .text{
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        font-family: RobotoLight, Arial, sans-serif;
      }
    }
  }

  .mobileBlock{
    display: none;

    &.mainTextBig{
      .text{
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        font-family: RobotoLight, Arial, sans-serif;
      }
    }
  }

  &.withBorder{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid grey4;
  }

  &:last-child{
    margin-bottom: 0;
  }

  @media (max-width: 1024px){
    flex-direction: column;

    .block{
      display: none;
    }

    .mobileBlock{
      display: flex;
      flex-direction: column;
      width: 100%;

      .redNote{
        margin-bottom: 20px;
      }

      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
  }
}

.blockFull{
  margin-bottom: 20px;
  width: 100%;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;
  white-space: pre-line;

  b{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  ul, ol{
    margin: 0;

    li{
      margin: 0;
    }
  }

  &.blockFullMarginTop{
    margin-top: 20px;
  }

  &:last-child{
    margin-bottom: 0;
  }
}

.text{
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;
  margin-bottom: 20px;

  b{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  p{
    &:not(:first-child){
      margin-top: 18px;
    }
  }

  ul, ol{
    margin: 0;

    li{
      margin: 0;
    }
  }

  &:only-child{
    margin-bottom: 0;
  }

  @media (max-width: 1024px){
    margin-bottom: 0;
  }
}

.redNote{
  background: red;
  border-radius: 5px;
  padding: 15px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: whiteBasic;

  &.redNoteTextThin{
    .redNoteText{
      font-family: RobotoLight, Arial, sans-serif;
    }
  }
}

.redNoteTitle{
  font-family: RobotoBold, Arial, sans-serif;
  margin-bottom: 10px;
}

.redNoteText{
  font-family: RobotoMedium, Arial, sans-serif;
}

.blueNote{
  border: 1px dashed navy;
  border-radius: 5px;
  padding: 15px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: navy;

  &.blueNoteTextGrey{
    .blueNoteText{
      color: grey1;
      font-family: RobotoLight, Arial, sans-serif;
    }
  }
}

.blueNoteTitle{
  font-family: RobotoBold, Arial, sans-serif;
  margin-bottom: 10px;
}

.blueNoteText{
  font-family: RobotoItalic, Arial, sans-serif;

  b{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  ul, ol{
    margin: 0;

    li{
      margin: 0;
    }
  }
}


.examplesTitle{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: grey2;

  &:not(:last-child){
    margin-bottom: 10px;
  }

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
  }
}

.examples{
  border-top: 1px solid grey4;
  border-bottom: 1px solid grey4;
  padding: 20px 0;
  margin-bottom: 20px;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;

  &:first-child{
    border-top: none;
    padding-top: 0;
  }
}

.examplesSingle{
  border-top: 1px solid grey4;
  padding-top: 20px;
  margin-top: 20px;
}

.examplesMultiple{
  ol{
    padding-left: 0;
    margin: 0;
    list-style-type: none;

    li{
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }
}

.example{
  width: fit-content;
  max-width: 100%;
}

.exampleImage{
  max-width: 100%;
}

.marker{
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;
  margin-bottom: 10px;
}

.exampleTitle{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: grey1;
  margin-bottom: 20px;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
  }
}

.details{
  border-top: 1px solid grey4;
  border-bottom: 1px solid grey4;
  padding: 20px 0;
  margin-bottom: 20px;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;

  &:first-child{
    padding: 0 0 20px;
    border-top: none;
  }

  &:last-child{
    border-bottom: none;
    margin-bottom: 0;
  }

  &.noBorder{
    .detailsText{
      padding: 0;
      margin-top: 20px;
      border: none;
    }
  }

  &.noShowMore{
    padding: 10px 0 0;
    border-bottom: none;
  }
}

.detailsTitle{
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: navy;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
  }
}

.switcher{
  cursor: pointer;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: grey2;

  &:not(:last-child){
    margin-bottom: 10px;
  }
}

.detailsText{
  margin-top: 10px;
  padding: 20px;
  border: 1px solid blue;
  white-space: pre-line;

  .blocks{
    margin-bottom: 0;

    .block{
      b{
        font-family: RobotoBold, Arial, sans-serif;
        font-weight: normal;
      }

      ul, ol{
        margin: 0;

        li{
          margin: 0;
        }
      }
    }
  }

  img{
    max-width: 100%;
  }

  @media (max-width: 1024px){
    padding: 10px;
    flex-direction: column;

    .block{
      display: flex;
      flex: 1;
      max-width: calc(100%);
    }
  }
}

.note{
  background: whiteBasic;
  border: 1px solid grey5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: grey1;
}

.noteHeading{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: RobotoBold, Arial, sans-serif;
}

.noteIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.noteText{
  font-family: RobotoItalic, Arial, sans-serif;
}

.bottomBlock{
  display: flex;
  align-items: stretch;

  @media (max-width: 1024px){
    flex-direction: column;

    .seeMore{
      max-width: calc(100%);
      margin-right: 0;

      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
  }
}

.seeMore{
  flex: 1;
  max-width: 334px;
  margin-right: 20px;
  background: grey5;
  border-radius: 5px;
  padding: 15px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: grey1;
}

.seeMoreTitle{
  font-family: RobotoBold, Arial, sans-serif;
  margin-bottom: 10px;
}

.link{
  font-family: RobotoItalic, Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;

  &:not(:last-child){
    margin-bottom: 10px;
  }
}

.footer{
  flex: 1;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;
}

ul{
  list-style-type: disc;
  padding-left: 25px;
}

ol{
  padding-left: 25px;
}

iframe{
  width: 100%;
  min-height: 600px;
  margin-bottom: 30px;
}

.mainTextBig{
  .blockFull{
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    font-family: RobotoLight, Arial, sans-serif;
  }
}

.textBlockWrapper{
  &:not(:last-child){
    margin-bottom: 20px;
  }
}
