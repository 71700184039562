@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 410px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 270px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 230px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  padding: 53px 10px 0px;

  @media (max-width: 1024px){
    transform: scale(0.65);
  }

  @media (max-width: 768px){
    padding: 59px 0 0px;
    transform: scale(0.4);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.2);
  }
}

.cardsWrapper{
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.infoBlock {
  flex-shrink: 0;
  width: 260px;

  &:not(:last-child){
    margin-right: 75px;
  }

  &.infoBlockMiddle{
    z-index: 1;
    width: 450px;

    .mainContent{
      min-height: 500px;
    }

    .contentText{
      width: 300px;
      max-width: 300px;
      font-family: RobotoMedium, Arial, sans-serif;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.05em;
      border: 3px solid navy;
      padding: 7px 10px;

      &:not(:last-child){
        margin-bottom: 50px;
      }

      .linkLeft, .linkRight{
        svg{
          path{
            fill: navy;
            transition: .3s ease;
          }
        }
      }

      &:hover{
        background: navy;
        color: whiteBasic;
        border: 3px solid red;

        .linkLeft, .linkRight{
          svg{
            path{
              fill: red;
            }
          }
        }
      }

      &:last-child{
        &:hover{
          background: navy;
          color: whiteBasic;
          border: 3px solid navy;
        }
      }
    }
  }
}

.mainContent{
  height: 100%;
  min-height: 400px;
  position: relative;
  width: 100%;
  background: whiteBasic;
  filter: drop-shadow(8px 8px 12px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.header{
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: whiteBasic;
  display: flex;
  align-items: center;
  justify-content: center;
  background: navy;
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
}

.content{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentText{
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.05em;
  color: navy;
  padding: 6px 10px;
  border: 4px solid navy;
  box-sizing: border-box;
  max-width: 200px;
  width: 100%;
  transition: .3s ease;

  &:not(:last-child){
    margin-bottom: 16px;
  }

  &.contentTextSecond{
    transform: translateY(2px);
  }

  &:hover{
    background: navy;
    color: whiteBasic;
  }
}

.linkLeft{
  left: 0;
  transform: translateX(-100%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  &.linkLeftTop{
     top: 33px;
   }

  &.linkLeftBottom{
    bottom: 32px;
  }
}

.linkRight{
  right: 0;
  transform: translateX(100%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wireLeft{
  left: 0;
  top: 50%;
  transform: translateX(calc(-100% - 64px)) translateY(-2px);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wireRight{
  right: 0;
  top: 50%;
  transform: translateX(calc(100% + 64px)) translateY(-2px);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connection{
  width: 16px;
  height: 4px;
  background: navy;
  flex-shrink: 0;
}

.contentHint{
  font-family: RobotoItalic, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: red;

  &:not(:last-child){
    margin-bottom: 16px;
  }

  &.imageHint{
    font-family: RobotoMedium, Arial, sans-serif;
  }
}

.contentImage{
  position: relative;
  padding: 6px 10px;
  border: 4px solid navy;
  min-height: 160px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child){
    margin-bottom: 20px;
  }
}

.image{
  width: 100%;
  height: auto;
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
