@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';

.wrapper{
  max-width: 100%;
  display: flex;
  align-items: center;

  &.grey{
    svg{
      path{
        fill: grey2;
      }
    }

    .square{
      &:before{
        background: grey2;
      }
    }

    .circle{
      background: grey2;
    }
  }

  &.greyRed{
    svg{
      path{
        fill: grey2;
      }
    }

    .square{
      &:before{
        background: grey2;
      }
    }

    .circle{
      background: grey2;
    }

    .arrow{
      svg{
        path{
          fill: red;
        }
        rect{
          fill: red;
        }
      }
    }
  }
}

.arrowBlock{
  display: flex;
  align-items: center;
  position: relative;
  transform: translateY(-50px);
  margin-right: 5px;
}

.arrowText{
  position: absolute;
  z-index: 1;
  left: 6px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 9.6px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: whiteBasic;
}

.arrowTextDots{
  position: absolute;
  z-index: 1;
  right: 30px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 9.6px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: whiteBasic;
}

.mainBlock{
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftBlock, .rightBlock{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60px;
  transform: translateY(-30px);
}

.mainBlockText{
  width: 100%;
  font-family: RobotoBoldItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: grey2;
  margin-bottom: 5px;
  padding: 0 4px;
}

.icon{
  display: flex;
  align-items: center;
  justify-content: center;
}

.middleBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconText{
  position: relative;
  z-index: 1;
}

.square{
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: whiteBasic;
  position: relative;

  &:before{
    content: '';
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    background: red;
    transform: rotate(-45deg);
    position: absolute;
  }
}

.circle{
  width: 40px;
  height: 40px;
  background: green;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: whiteBasic;
}

.arrow{
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &:last-child{
      max-height: 68px;
  }

  &:nth-last-child(2){
    max-height: 68px;
  }
}

.label{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: blue;
  border: 3px solid blue;
  border-radius: 6px;
  background: whiteBasic;
  padding: 3px;
}
