@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value blackBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 350px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 250px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 230px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 53px 10px 0px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 0px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.3);
  }
}

.infoLeft, .infoRight{
  display: flex;
  align-items: center;
}

.infoLeft{
  margin-right: 46px;
}

.infoRight{
  margin-left: 46px;
}

.infoContent{
  width: 835px;
  background: whiteBasic;
  border-radius: 10px;
  padding: 40px 20px 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoTop{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.infoBottom{
  width: 100%;
}

.divider{
  width: 100%;
  border-top: 1px solid grey4;
  margin-top: 40px;
  margin-bottom: 20px;
}

.stepEndBlock{
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
}

.endText{
  white-space: nowrap;
  width: fit-content;
  display: flex;
  align-items: center;
  font-family: RobotoBoldItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: grey2;
  transform: rotate(-90deg);

  &:before,
  &:after{
    content: '';
    flex: 1;
    border-top: 2px dotted grey1;
    width: 38px;
  }

  &:before{
    margin-right: 12px;
  }

  &:after{
    margin-left: 12px;
  }
}

.steps{
  width: 100%;
  display: flex;
  align-items: stretch;
  margin-bottom: 16px;
}

.step1,
.step2,
.step3,
.step4{
  flex: 1;
  font-family: RobotoBoldItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: blackBasic;
  display: flex;
  flex-direction: column;
  white-space: pre-line;

  &:after{
    content: '';
    height: 0px;
    width: 100%;
    border: 2px solid grey3;
    border-radius: 2px;
    margin-top: 7px;
    box-sizing: border-box;
  }
}

.number{
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 7px;
}

.stepText{
  flex: 1;
}

.step1{
  max-width: 117px;
  margin-right: 128px;
}

.step2{
  max-width: 133px;
  margin-right: 10px;
}

.step3{
  max-width: 100px;
  margin-right: 10px;
}

.step4{
  max-width: 260px;
  margin-right: 38px;
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
