@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 500px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 350px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 230px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1140px;
  padding: 53px 10px 50px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 50px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.2);
  }
}

.cardsWrapper{
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 540px;
  flex-shrink: 0;
}

.infoBlock {
  width: 210px;
  height: 210px;
  flex-shrink: 0;
  position: relative;

  &:nth-child(1),
  &:nth-child(2) {
    margin-bottom: 120px;
  }

  .arrow{
    position: absolute;
    top: 0;
    z-index: 3;
  }

  .hint{
    position: absolute;
    //font-family: Helvetica Neue LT Std;
    font-family: Roboto, Arial, sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: grey1;

    .hintTitle{
      font-size: 28px;
      line-height: 34px;
    }
  }
}

.mainContent{
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;

  &.detailed{
    display: flex;
    flex-direction: column;
    padding: 15px 5px 5px;
    box-shadow: none;
    position: relative;
    z-index: 3;
  }

  &.bottom{
    position: absolute;
    top: 0;
    left: 0;

    &:nth-child(2){
      z-index: 2;
      transform: translate(14px, 30px);
    }

    &:nth-child(3){
      z-index: 1;
      transform: translate(28px, 60px);
    }
  }

  .info{
    margin-top: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: whiteBasic;
    border-radius: 10px;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: grey1;
    padding: 10px 10px 5px;
    text-align: left;
  }

  .bottomText{
    margin-top: 5px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

.yellowColored{
  .mainContent{
    background: yellowBasic;
  }
}

.blueColored{
  .mainContent{
    background: blue;
    color: whiteBasic;
  }

  .arrow{
    right: 1px;
    transform: translateX(100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hint{
    top: 50%;
    right: 0;
    transform: translate(calc(100% + 70px), -50%);
    max-width: 220px;
  }
}

.greenColored{
  .mainContent{
    background: green;
  }
}

.redColored{
  .mainContent{
    background: red;
    color: whiteBasic;
  }
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
