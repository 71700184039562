@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;
  white-space: pre-line;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;
  white-space: pre-line;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 460px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 290px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 230px;
  }
}

.infoWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  padding: 23px 30px 50px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 50px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    padding: 59px 0 50px;
    transform: scale(0.25);
  }
}

.topContent{
  margin-bottom: 50px;
}

.mainContent{
  display: flex;
  align-items: center;
  justify-content: center;
}

.outerContainer{
  padding: 55px 0 35px;
  border: 1px dashed navy;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: greyBackground;
  transition: .3s ease;
  cursor: pointer;

  &:hover{
    background: whiteBasic;
  }
}

.middleContainer{
  padding: 48px 12px;
  border: 1px dashed navy;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: greyBackground;
  transition: .3s ease;
  cursor: pointer;

  &:hover{
    background: whiteBasic;
  }
}

.innerContainer{
  padding: 39px 20px 19px;
  border: 1px dashed navy;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  background: greyBackground;
  transition: .3s ease;
  cursor: pointer;

  &:hover{
    background: whiteBasic;
  }

  &:not(:last-child){
    margin-right: 20px;
  }
}

.label{
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: navy;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 38px;
  padding: 0 10px;
  cursor: pointer;

  &.cornerLabel{
    top: 0;
    left: 0;
    justify-content: flex-start;
  }

  &.centerLabel{
    bottom: 5px;
    left: 0;
    justify-content: center;
    z-index: 2;
  }

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.iconStart{
  transform: translateY(20px);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-right: 35px;

  .icon{
    background: whiteBasic;
    border: 3px solid navy;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: .3s ease;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &:hover{
      background: navy;
      border: 3px solid red;

      .hintText{
        opacity: 1;
      }
    }
  }

  .hintText{
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: .3s ease;
    transform: translateY(-100%);
    height: 22px;
    display: flex;
    align-items: center;
    font-family: Roboto, Arial, sans-serif;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: navy;
    flex-shrink: 0;
  }

  .arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    transform: translateX(100%);
  }
}

.iconEnd{
  transform: translateY(20px);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 35px;
  position: relative;

  .icon{
    background: whiteBasic;
    border: 5px solid navy;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: .3s ease;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &:hover{
      background: navy;
      border: 5px solid red;

      .hintText{
        opacity: 1;
      }
    }
  }

  .hintText{
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: .3s ease;
    transform: translateY(-100%);
    height: 26px;
    display: flex;
    align-items: center;
    font-family: Roboto, Arial, sans-serif;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: navy;
    flex-shrink: 0;
  }

  .arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    transform: translateX(-100%);
  }
}

.connection{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
  z-index: 1;
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
