@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 810px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 570px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 300px;
  }
}

.infoWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  padding: 53px 10px 50px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 50px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.25);
  }
}

.topWrapper{
  background: whiteBasic;
  border: 1px solid grey3;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 70px;
}

.topLabel{
  margin-bottom: 50px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;
}

.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.headerTitle{
  font-family: Roboto, Arial, sans-serif;
  font-size: 25.2853px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: grey1;
  padding: 8px 13px;
  min-height: 48px;
  background: whiteBasic;
  border: 2px solid grey1;
  box-sizing: border-box;
  border-radius: 35.1185px;
}

.connection{
  transform: translateY(5px) translateX(70px);
  margin-bottom: -10px;
  cursor: pointer;
  position: relative;
  z-index: 3;

  .tooltipArrow{
    right: -75%;
    top: 0;
    transform: translateY(-58px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 0;
  }

  &:hover{
    .tooltipArrow{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.connection2{
  position: absolute;
  transform: translateY(-30px) translateX(28px);
  cursor: pointer;

  &:hover{
    .tooltipArrow{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.mainContent{
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.itemWrapper{
  margin-right: 10px;

  &:first-child{
    margin-right: 35px;
  }

  &:last-child{
    position: relative;
    z-index: 3;
  }
}

.tableTitle{
  margin-bottom: 20px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: navy;
  width: 100%;
}

.table{
  width: 100%;
  max-width: 456px;

  &:not(:last-child){
    margin-right: 30px;
  }
}

.row{
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid grey4;
  background: whiteBasic;

  &.greyColor{
    background: grey5;
  }

  &.blueColor{
    background: blue1;
  }

  &.yellowColor{
    background: yellowBasic;
  }

  &.bold{
    .cell2{
      font-family: RobotoMedium, Arial, sans-serif;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      color: navy;
      cursor: pointer;
      position: relative;

      &:hover{
        div{
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}

.cell1{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;
  width: 40px;
  border-right: 1px solid grey4;
}

.cell2{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;
  padding: 3px 5px;
}

.tooltip{
  position: absolute;
  z-index: 1;
  right: 40%;
  top: 25.5%;
}

.bottomWrapper{
  margin: 50px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 942px;
  position: relative;
  z-index: 2;
}

.exampleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  font-family: RobotoLight, Arial, sans-serif;
}

.dropDown{
  position: absolute;
  bottom: 0px;
  top: 60px;
  max-width: 392px;
  background-color: whiteBasic;
  border: 1px solid #F4F4F4;
  pointer-events: none;
  height: max-content;
  width: 50%;
  margin: 0 auto;
  max-height: 0px;
  overflow: hidden;
  z-index: 4;
}

.dropDownOpen{
  border: 1px solid grey4;
  max-height: max-content;
  pointer-events: visible;
}

.dropDownItem{
  padding: 5px 10px;
  &:not(:last-child){
    border-bottom: 1px solid grey4;
    cursor: pointer;
  }
  &:hover{
    background-color: navy;
    color: whiteBasic;
    transition: all 0.3s ease;
    .dropDownItemSubTitle{
      color: whiteBasic;
    }
  }
}

.dropDownItemTitle{
  margin: 0;
  font-weight: 500;
}

.dropDownItemSubTitle{
  color: #959595;
  transition: color 0.3s ease;
  font-size: 12px;
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
