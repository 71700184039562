@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value purple from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 650px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 440px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 230px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 53px 10px 50px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 50px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.23);
  }
}

.infoLeft, .infoRight{
  display: flex;
  align-items: center;
}

.infoLeft{
  margin-right: 46px;
}

.infoRight{
  margin-left: 46px;
}

.infoContent{
  width: 1040px;
  background: whiteBasic;
  border-radius: 10px;
  padding: 20px 20px 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoTop{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wrapper{
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .button{
    &.buttonWhite{
      &:last-child{
        max-width: 303px;
      }
    }
    &.buttonPurple{
      &:last-child{
        max-width: 303px;
      }
    }
  }

  &.wrapperTriple{
    .button{
      &:not(:first-child){
        max-width: 183px;
        margin-left: 25px;
      }
    }
  }
}

.button{
  min-height: 40px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  white-space: pre-line;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  position: relative;
  z-index: 4;

  &.buttonBlue{
    background: navy;
    color: whiteBasic;
    border: 2px solid navy;
    position: relative;
    cursor: pointer;

    &:hover{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &.buttonWhite{
    color: grey2;
    background: whiteBasic;
    border: 2px solid grey2;
  }

  &.buttonPurple{
    color: purple;
    background: whiteBasic;
    border: 2px solid purple;
  }
}

.mainContent{
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.contentWrapper{
  &:not(:last-child){
    margin-right: 5px;
  }

  &:last-child{
    margin-left: auto;
  }
}

.infoBottom{
  width: 100%;
  margin-top: 30px;
}

.divider{
  width: 100%;
  border-top: 1px solid grey4;
  margin-top: 40px;
  margin-bottom: 20px;
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
