@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  position: relative;
}

.infoWrapper{
  padding: 0 60px;
  width: 100%;

  @media (max-width: 768px){
    padding: 0 40px;
  }
}

.links{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-top: 40px;
  border-top: 1px solid grey4;
}

.popupLink{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 6px;
  background: whiteBasic;
  border: 1px solid grey1;
  border-radius: 10px;
  margin: 10px 0;
  cursor: pointer;
  transition: .3s ease;

  &:not(:last-child){
    margin-right: 10px;
  }

  &:hover{
    border: 1px solid blue;

    .link{
      color: blue;
    }

    .linkIcon{
      svg{
        path{
          fill: blue;
        }
      }
    }
  }
}

.link{
  padding: 0 5px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: grey1;
  transition: .3s ease;
}

.linkIcon{
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    path{
      transition: .3s ease;
    }
  }
}
