@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  &.small{
    @media (max-width: 600px){
      width: 100px;

      &.arrowRightAnimation{
        transform: translateX(calc(100% + 10px)) scale(0.4);
        transform-origin: 20% 50%;
      }

      &.arrowLeftAnimation{
        transform: translateX(calc(-100% - 10px)) scale(0.4);
        transform-origin: 80% 50%;
      }

      &.arrowDownAnimation{
        transform: translateX(50%) translateY(calc(100px - 25px)) rotate(90deg) scale(0.4);
        transform-origin: 60% 0%;

        &.right{
          transform: translateX(calc(25% + 5px)) translateY(calc(100px - 25px)) rotate(90deg) scale(0.4);
        }
      }

      &.arrowUpAnimation{
        transform: translateX(50%) translateY(calc(-100px + 25px)) rotate(-90deg) scale(0.4);
        transform-origin: 60% 100%;

        &.left{
          transform: translateX(calc(75% - 5px)) translateY(calc(-100px + 25px)) rotate(-90deg) scale(0.4);
        }
      }
    }
  }
}

.arrowRightAnimation{
  height: 33px;
  width: 100px;
  position: absolute;
  right: 0;
  top: 10px;
  transform: translateX(calc(100% + 10px));
  overflow: hidden;

  .arrowsInnerContainer{
    animation: leftToRight steps(10, end) infinite 3s;
    justify-content: flex-start;
  }
}

.arrowLeftAnimation{
  height: 33px;
  width: 100px;
  position: absolute;
  left: 0;
  bottom: 10px;
  transform: translateX(calc(-100% - 10px));
  overflow: hidden;

  .arrow{
    transform: scale(-1);
  }

  .arrowsInnerContainer{
    animation: rightToLeft steps(10, end) infinite 3s;
    justify-content: flex-end;
  }
}

.arrowDownAnimation{
  height: 33px;
  width: 100px;
  position: absolute;
  right: 50%;
  bottom: 0;
  transform: translateX(50%) translateY(calc(100px - 25px)) rotate(90deg);
  overflow: hidden;

  .arrowsInnerContainer{
    animation: leftToRight steps(10, end) infinite 3s;
    justify-content: flex-start;
  }

  &.right{
    transform: translateX(calc(25% + 5px)) translateY(calc(100px - 25px)) rotate(90deg);
  }
}

.arrowUpAnimation{
  height: 33px;
  width: 100px;
  position: absolute;
  right: 50%;
  top: 0;
  transform: translateX(50%) translateY(calc(-100px + 25px)) rotate(-90deg);
  overflow: hidden;

  .arrowsInnerContainer{
    animation: leftToRight steps(10, end) infinite 3s;
    justify-content: flex-start;
  }

  &.left{
    transform: translateX(calc(75% - 5px)) translateY(calc(-100px + 25px)) rotate(-90deg);
  }
}

.arrowsInnerContainer{
  display: flex;
  align-items: center;
  width: 100px;
  height: 100%;
}

.arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 33px;

  .arrowImage{
    height: 55px;
    width: auto;
  }

  &:nth-child(1){
    opacity: 0.25;
  }

  &:nth-child(2){
    opacity: 0.5;
  }

  &:nth-child(3){
    opacity: 0.75;
  }

  &:nth-child(4){
    opacity: 1;
  }
}

@keyframes leftToRight {
  0%{
    transform: translateX(-100px);
  }
  100%{
    transform: translateX(100px);
  }
}

@keyframes rightToLeft {
  0%{
    transform: translateX(100px);
  }
  100%{
    transform: translateX(-100px);
  }
}
