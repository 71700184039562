@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';

.button{
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &:hover{
    .text{
      background: navy;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .textWithoutBg{ 
      box-shadow: none;
    }

    .toolTip{
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.disabled{
    pointer-events: none;

    .text{
      background: grey5;
    }
  }
}

.tooltipContent{
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
}

.tooltipTitle{
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: navy;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: whiteBasic;
  text-decoration: none;
}

.linkIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.text{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: whiteBasic;
  background: blue;
  border-radius: 5px;
  padding: 8px;
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .3s ease;
}

.textWithoutBg {
  background: none;
  box-shadow: none;
  padding: 0;
}

.toolTip{
  cursor: text;
  max-width: 250px;
  width: 100vw;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 32px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: whiteBasic;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: grey2;

  .backArea{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 35px;
  }

  .tooltipTail{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
  }

  .tooltipText{
    background: whiteBasic;
    padding: 8px;
    text-align: left;
    position: relative;
    z-index: 1;

    p, ul, li{
      margin: 0;
    }

    ul{
      padding-left: 24px;
    }

    li::marker{
      color: grey2;
    }

    a{
      color: blue;
      text-decoration: none;
    }
  }
}
