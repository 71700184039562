@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../assets/styles/abstracts/colors.scss';
@value greyBorder from '../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../assets/styles/abstracts/colors.scss';
@value orangeLight from '../../../assets/styles/abstracts/colors.scss';
@value greenLight from '../../../assets/styles/abstracts/colors.scss';
@value purpleLight from '../../../assets/styles/abstracts/colors.scss';
@value yellowLight from '../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.background{
  width: 100%;
  height: 100%;
  position: absolute;
}

.container{
  width: 100%;
  max-width: 924px;
  position: relative;
  z-index: 1;
  background: whiteBasic;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 1024px){
    max-width: calc(100% - 32px);
    overflow-y: hidden;
  }
}

.mobileWrapper{
  display: flex;
  justify-content: center;
  height: fit-content;

  @media (max-width: 1024px){
    width: calc(100% - 32px - 30px - 44px);
    transform: scale(0.6);
    transform-origin: 60% 0%;
  }

  @media (max-width: 1000px){
    transform-origin: 40% 0%;
  }

  @media (max-width: 900px){
    transform-origin: 30% 0%;
  }

  @media (max-width: 800px){
    transform-origin: 25% 0%;
  }

  @media (max-width: 768px){
    width: calc(100% - 32px - 16px - 30px);
  }

  @media (max-width: 700px){
    transform: scale(0.6);
    transform-origin: 10% 0%;
  }

  @media (max-width: 600px){
    transform: scale(0.4);
    transform-origin: 5% 0%;
  }

  @media (max-width: 450px){
    transform: scale(0.35);
    transform-origin: 5% 0%;
  }

  @media (max-width: 400px){
    transform: scale(0.3);
    transform-origin: 5% 0%;
  }

  @media (max-width: 360px){
    transform: scale(0.3);
    transform-origin: 3% 0%;
  }
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: navy;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
    padding: 20px 16px;
  }
}

.isoLabel{
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;
}

.closeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  cursor: pointer;

  svg{
    transition: .3s ease;
    transform: rotate(-90deg);

    path{
      transition: .3s ease;
    }
  }

  &:hover{
    svg{
      transform: rotate(0deg);

      path{
        fill: navy;
      }
    }
  }

  @media (max-width: 768px){
    margin-left: 20px;
  }
}

.body{
  padding: 30px 30px 30px 44px;
  border-top: 1px solid greyBorder;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 11px;
  line-height: 13px;
  color: grey3;
  text-align: center;
  display: flex;
  position: relative;

  @media (max-width: 1024px){
    max-width: 100%;
    height: 460px;
  }

  @media (max-width: 768px){
    padding: 20px 16px 20px 16px;
  }

  @media (max-width: 600px){
    height: 305px;
  }

  @media (max-width: 450px){
    height: 270px;
  }

  @media (max-width: 400px){
    height: 240px;
  }
}

.map{
  padding: 60px 60px 47px 0;
}

.connections{
  position: relative;
  height: fit-content;
}

.home{
  width: 40px;
  transform: translateY(229px);
  height: fit-content;
}

.homeIcon{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.homeTitle{
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: grey2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.blueLine{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 336px;
  top: 52px;
  z-index: 3;
}

.orangeLine{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 188px;
  top: 412px;
  z-index: 3;
}

.greenLine{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 188px;
  top: 502px;
  z-index: 3;
}

.purpleLine{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40px;
  top: 592px;
  z-index: 3;
}

.yellowTopLine{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40px;
  top: 142px;
  z-index: 3;
}

.yellowMiddleLine{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40px;
  top: 232px;
  z-index: 3;

  .link{
    max-width: 134px;
  }
}

.yellowBottomLine{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40px;
  top: 322px;
  z-index: 3;

  .dot{
    &.yellowDot{
      &:not(:last-child){
        margin-right: 54px;
      }

      &:nth-child(even){
        .link{
          bottom: 0;
          transform: translateY(calc(100% + 10px));
        }
      }
    }
  }

  .link{
    max-width: 134px;
  }
}

.dot{
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover{
    .link{
      font-family: RobotoMedium, Arial, sans-serif;
    }
  }

  &.active{
    .point{
      width: 32px;
      height: 32px;
      border: 7px solid grey2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:before{
        width: 8px;
        height: 8px;
      }

      &:after{
        width: 40px;
        height: 40px;
      }
    }
  }

  &.blueDot{
    .point{
      background: blue1;
    }

    .link{
      top: 0;
      transform: translateY(calc(-100% - 10px));
    }

    &:not(:last-child){
      margin-right: 128px;
    }
  }

  &.yellowDot{
    .point{
      background: yellowLight;
    }

    .link{
      top: 0;
      transform: translateY(calc(-100% - 10px));
    }

    &:not(:last-child){
      margin-right: 128px;
    }
  }

  &.orangeDot{
    .point{
      background: orangeLight;
    }

    .link{
      bottom: 0;
      transform: translateY(calc(100% + 10px));
      max-width: 134px;
    }

    &:not(:last-child){
      margin-right: 128px;
    }
  }

  &.greenDot{
    .point{
      background: greenLight;
    }

    .link{
      bottom: 0;
      transform: translateY(calc(100% + 10px));
      max-width: 134px;
    }

    &:not(:last-child){
      margin-right: 128px;
    }
  }

  &.purpleDot{
    .point{
      background: purpleLight;
    }

    .link{
      bottom: 0;
      transform: translateY(calc(100% + 10px));
      max-width: 134px;
    }

    &:not(:last-child){
      margin-right: 128px;
    }
  }
}

.link{
  width: 120px;
  position: absolute;
  z-index: 3;
  color: grey3;
  font-family: RobotoRegular, Arial, sans-serif;
  transition: .3s ease;
  white-space: pre-line;
}

.point{
  border: 2px solid grey2;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
  transition: .3s ease;
  cursor: pointer;

  &:before{
    content: '';
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 50%;
    background: grey2;
    position: absolute;
    transition: .3s ease;
  }

  &:after{
    content: '';
    width: 20px;
    height: 20px;
    border: 1px solid grey2;
    flex-shrink: 0;
    border-radius: 50%;
    position: absolute;
    transition: .3s ease;
  }

  &:hover{
    width: 28px;
    height: 28px;

    &:before{
      width: 10px;
      height: 10px;
    }

    &:after{
      width: 28px;
      height: 28px;
    }
  }

  &:active{
    width: 32px;
    height: 32px;
    border: 7px solid grey2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:before{
      width: 8px;
      height: 8px;
    }

    &:after{
      width: 40px;
      height: 40px;
    }
  }
}
