@value grey4 from '../../../../assets/styles/abstracts/colors.scss';

.bottomLine {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: RobotoMedium, Arial, sans-serif;
    & svg {
        display: flex;
        align-items: center;
    }
}
.bottomLineLonger {
    margin: 60px -20px;
}

.text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.text2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: grey4;
}
