@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value blackBasic from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
  margin: -20px 0;
}

.container{
  width: 100%;
}

.infoWrapper{
  padding: 60px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 29.69%, #FFFFFF 48.44%, #FFFFFF 65.63%, rgba(255, 255, 255, 0) 100%);
  width: 100%;

  &:first-child{
    padding: 10px 60px;

    @media (max-width: 600px){
      padding: 10px 16px;
    }
  }

  &:last-child{
    padding: 25px 60px;

    @media (max-width: 600px){
      padding: 25px 16px;
    }
  }

  &:not(:last-child){
    margin-bottom: 30px;
  }

  &.middle{
    margin-bottom: 30px;
    padding: 30px 60px;

    @media (max-width: 600px){
      padding: 30px 16px;
    }
  }

  @media (max-width: 1024px){
    padding: 50px 16px;
  }

  @media (max-width: 600px){
    padding: 40px 16px;
  }
}

.text{
  padding: 26px 0;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: grey1;
  max-width: 654px;
  margin: 0 auto 20px;

  .bold{
    font-family: RobotoBold, Arial, sans-serif;
  }

  @media (max-width: 600px){
    font-size: 16px;
    line-height: 19px;
  }
}

.innerContainer{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: blue;
  margin-bottom: 20px;

  @media (max-width: 1024px){
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: 600px){
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 21px;
  }
}

.subTitle{
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: grey1;
  margin-top: -10px;
  margin-bottom: 20px;

  @media (max-width: 600px){
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 19px;
  }
}

.link{
  margin: 0 auto;
  width: fit-content;
  cursor: pointer;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: blue;
  display: flex;
  align-items: center;

  .linkText{
    padding: 5px;
  }

  @media (max-width: 1024px){
    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: 600px){
    font-size: 16px;
    line-height: 19px;
  }

  .icon{
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      path{
        fill: blue;
      }
    }

    &:first-child{
      margin-right: 9px;
    }

    &:last-child{
      margin-left: 12px;
    }
  }
}

.buttonsBlock{
  margin: 20px auto 0;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: greyBackground;
  padding: 20px;
  border-radius: 70px;

  &:not(:last-child){
    margin-bottom: 20px;
  }

  @media (max-width: 600px){
    flex-direction: column;
    margin-top: 10px;

    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
}

.button{
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whiteBasic;
  border: 3px solid navy;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 250px;
  height: 100px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;
  transition: .3s ease;
  position: relative;

  &:hover{
    background: navy;
    color: whiteBasic;
    border: 3px solid navy;

    div{
      opacity: 1;
    }
  }

  &.buttonDashed{
    border: 1px dashed navy;

    &:hover{
      background: navy;
      color: whiteBasic;
      border: 1px solid navy;
    }

    &:not(:last-child){
      margin-right: 50px;

      @media (max-width: 768px){
        margin-right: 30px;
      }

      @media (max-width: 600px){
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  &.buttonComplex{
    flex-direction: column;
    border: 3px solid yellowBasic;

    &:hover{
      background: whiteBasic;
      color: navy;
      border: 3px solid yellowBasic;

      .top{
        background: navy;
        color: whiteBasic;
      }
    }
  }

  @media (max-width: 1024px){
    width: 200px;
    height: 75px;
    font-size: 18px;
    line-height: 21px;
  }
}

.arrowIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;

  @media (max-width: 768px){
    margin: 0 10px;
  }

  @media (max-width: 600px){
    transform: rotate(90deg);
  }
}

.top{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: whiteBasic;
  color: navy;
  transition: .3s ease;
  border-radius: 50px 50px 0 0;
}

.bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  background: yellowBasic;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: grey2;
  padding: 0 36px;
  text-align: left;
  border-radius: 0 0 50px 50px;

  @media (max-width: 1024px){
    font-size: 14px;
    line-height: 15px;
  }
}

.flagIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.blocks{
  display: flex;
  align-items: stretch;
  justify-content: center;

  @media (max-width: 1120px){
    flex-direction: column;
  }
}

.block{
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: whiteBasic;
  border: 1px solid blue;
  border-radius: 10px;
  padding: 10px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: blackBasic;

  &:not(:last-child){
    margin-right: 10px;

    @media (max-width: 1120px){
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &:nth-child(1){
    max-width: 422px;

    @media (max-width: 1120px){
      max-width: 100%;
    }
  }

  &:nth-child(2){
    max-width: 325px;

    @media (max-width: 1120px){
      max-width: 100%;
    }
  }

  &:nth-child(3){
    max-width: 222px;

    @media (max-width: 1120px){
      max-width: 100%;
    }
  }
}

.blockIcon{
  margin-right: 10px;
  padding: 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: navy;
  background: greyBackground;
  border-radius: 10px;
  transition: .3s ease;

  svg{
    path{
      transition: .3s ease;
    }
  }

  &:hover{
    cursor: pointer;
    background: navy;
    color: whiteBasic;
     svg{
       path{
         fill: whiteBasic
       }
     }
  }
}

.blockIconBig {
  margin-right: 10px;
  padding: 15px;
  background: greyBackground;
  border-radius: 10px;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg{
    height: 100%;
    width: auto;
  }

  @media (max-width: 1120px){
    width: 60px;
    height: 60px;

    &:first-child{
      margin-bottom: 70px;
    }

    &:not(:first-child){
      position: absolute;
      top: 80px;
      letter-spacing: 10px;
    }
  }
}
