@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
    max-width: calc(100vw - 42px - 42px);
    margin: 0 auto 8px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
    max-width: calc(100vw - 42px - 42px);
    margin: 0 auto;
  }
}

.isoLabel{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 510px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 340px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 230px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 53px 10px 0px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 0px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.3);
  }

  @media (max-width: 425px){
    transform: scale(0.25);
  }
}

.infoBlock{
  position: relative;
  z-index: 3;
  width: 300px;

  &:first-child{
    margin-left: auto;
  }

  &:last-child{
    margin-right: auto;
  }

  &:not(:first-child){
    margin-left: 20px;
  }

  &.deepBlue{
    z-index: 2;
    .body{
      background: blue;
    }
  }

  &.darkBlue {
    z-index: 1;

    .body {
      background: navy;
    }
  }

  .sideLink{
    pointer-events: none;
    z-index: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(calc(122px), -50%);
    display: flex;
    align-items: center;

    .midBlock{
      transform: rotate(90deg) translateY(-100%);
      position: relative;
      z-index: 1;
    }

    .arrow{
      &:first-child{
        transform: translateX(80px);
      }
      &:last-child{
        transform: translateX(-2px);
      }
    }
  }

  .header{
    white-space: pre-line;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: grey1;
    padding: 14px 30px;
    font-family: RobotoMedium, Arial, sans-serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    color: whiteBasic;
    border-radius: 10px 10px 0px 0px;
  }

  .body{
    min-height: 500px;
    padding: 52px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
    background: blue1;
  }

  .textBlock{
    width: 200px;
    min-height: 130px;
    font-family: RobotoMedium, Arial, sans-serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    position: relative;
    transition: .3s ease;

    &:first-child{
      z-index: 2;

      &:before{
        content: '';
        width: calc(560px - 237px);
        height: calc(422px - 178px);
        position: absolute;
        top: -20px;
        left: -20px;
      }

      .toolTip{
        transform: translate(-50%, calc(-100% - 47px));
      }
    }

    .text{
      z-index: 1;
      position: relative;
      border-radius: 10px;
      background: whiteBasic;
      color: grey1;
      width: 100%;
      height: 100%;
      min-height: 130px;
      padding: 23px;
      box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.25);
      transition: .3s ease;
      white-space: pre-line;
    }

    &.hoverable{
      cursor: pointer;

      &:hover{
        .figureBasic{
          opacity: 0;
        }

        .figureHover{
          opacity: 1;
        }

        div{
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    .hoverable{
      cursor: pointer;

      &:hover{
        .figureBasic{
          opacity: 0;
        }

        .figureHover{
          opacity: 1;
        }

        div{
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    .figureBasic,
    .figureHover{
      position: absolute;
      z-index: 1;
      bottom: -20px;
      left: -19px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .small{
      .figure{
        width: 237px;
      }
    }

    .big{
      .figure{
        width: 560px;
      }
    }

    .figureHover{
      opacity: 0;
      pointer-events: auto;
      transition: .3s ease;
    }

    .figureBasic{
      transition: .3s ease;
    }
  }

  .midBlock{
    background: greyBackground;
    border-radius: 20px;
    font-family: RobotoLight, Arial, sans-serif;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.05em;
    color: grey1;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
