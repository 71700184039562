@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value blue2 from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  background: blue1;
  border: 1px dashed grey1;
  border-radius: 40px;
  padding: 16px;

  &.themeLightBlue{
    background: blue2;
  }

  &.themeTransparent{
    background: transparent;
    border: none;
    padding: 16px 0;

    &.smallSize{
      padding: 11px 0;
    }
  }

  &.smallSize{
    padding: 11px;

    .title{
      font-size: 16.8569px;
      line-height: 20px;
      min-width: 35px;
      min-height: 35px;
    }

    .arrow{
      margin-bottom: -8px;
    }

    .items{
      padding: 12px;
    }

    .contentSubtitle{
      font-size: 12.2161px;
      line-height: 14px;
      margin-top: 10px;
    }

    .subTitle{
      font-size: 16.2881px;
      line-height: 19px;
      margin-top: 7px;
    }

    .textItem{
      font-size: 16.8569px;
      line-height: 20px;
      min-width: 28px;
      min-height: 28px;
    }
  }

  &.narrowSize{
    .title{
      min-width: 50px;
      min-height: 50px;
    }

    .textItem{
      min-width: 40px;
      min-height: 40px;
    }
  }

  &.disabled{
    .title{
      opacity: 0.3;
    }

    .arrow{
      svg{
        opacity: 0.3;
      }
    }

    .items{
      background: rgba(255, 208, 4, 0.3);
      border: 1px solid rgba(44, 44, 44, 0.3);
    }

    .textItem{
      border: 1px solid rgba(44, 44, 44, 0.3);
      color: rgba(44, 44, 44, 0.3);
    }
  }

  &.withTooltip{
    .title{
      cursor: pointer;
    }
  }

  &.titleSmall{
    .title{
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.content{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.title{
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: grey1;
  background: whiteBasic;
  border: 1px solid grey1;
  box-sizing: border-box;
  border-radius: 50px;
  min-width: 162px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: pre-line;
  z-index: 3;
}

.titleTrigger{
  min-width: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    .tooltip{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.tooltip{
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.tooltipArrow{
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -100%);
}

.sideArrow{
  position: absolute;
  right: 0;
  transform: translateX(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;

  &.withTooltip{
    cursor: pointer;
  }

  &:hover{
    z-index: 4;

    .tooltipArrow{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.arrow{
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: -14px;

  &.right{
    transform: translateX(calc(50% - 4px));
  }

  &.left{
    transform: translateX(calc(-50% + 4px));
  }

  &:hover{
    z-index: 4;

    .tooltipArrow{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.items{
  background: yellowBasic;
  border: 1px solid grey1;
  box-sizing: border-box;
  border-radius: 60px;
  padding: 18px;
}

.itemsContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentSubtitle{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  letter-spacing: 0.05em;
  color: grey1;
  margin-top: 10px;

  &.withTooltip{
    cursor: pointer;
    position: relative;
    z-index: 3;
  }

  &:hover{
    .tooltip{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.contentSubTitleSmallFont{
  font-size: 10px;
}

.subTitle{
  max-width: 175px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;
  margin-top: 10px;

  &.withTooltip{
    cursor: pointer;
    position: relative;
    z-index: 3;
  }

  &:hover{
    .tooltip{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.textItem{
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: grey1;
  background: whiteBasic;
  border: 1px solid grey1;
  box-sizing: border-box;
  border-radius: 50px;
  min-width: 80px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  z-index: 3;
  position: relative;

  &.withTooltip{
    cursor: pointer;
  }

  &.transparent{
    opacity: 0.5;
  }

  &.fontMedium{
    font-size: 10px;
    line-height: 12px;
  }

  &.fontSmall{
    font-size: 8px;
    line-height: 9px;
  }

  &:hover{
    .tooltip{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.itemWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemArrow{
  position: relative;
  cursor: pointer;
  margin: 0 4px;

  .tooltipArrow{
    transform: translate(-50%, calc(-100% - 10px));
  }

  &:hover{
    z-index: 4;

    .tooltipArrow{
      div{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
