@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;
  white-space: pre-line;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 420px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 290px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 240px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 53px 10px 0px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 0px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.4);
  }
}

.cardsWrapper{
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  flex-shrink: 0;
}

.infoBlock {
  width: 100%;
}

.mainContent{
  color: grey1;
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  background: whiteBasic;
  border: 2px solid navy;
  box-sizing: border-box;
  border-radius: 5px;
  transition: .3s ease;

  &:hover{
    background: blue;
    color: whiteBasic;
    border: 2px solid blue;

    div{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.arrow{
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    path{
      fill: navy;
      transition: .3s ease;
    }
  }

  &:hover{
    svg{
      path{
        fill: blue;
      }
    }
  }
}

.okBlock{
  position: relative;
}

.ok{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: whiteBasic;
  margin: 32px 0;

  &:before, &:after{
    content: '';
    height: 32px;
    width: 4px;
    background: grey1;
    position: absolute;
    left: 50%;
  }

  &:before{
    transform: translateX(-50%) translateY(-100%);
    top: 0;
  }

  &:after{
    transform: translateX(-50%) translateY(100%);
    bottom: 0;
  }

  &:hover{
    .background{
      svg{
        path{
          fill: grey2;
        }
      }
    }
  }
}

.background{
  height: 71px;
  width: 71px;

  svg{
    path{
      fill: grey1;
      transition: .3s ease;
    }
  }
}

.okText{
  position: absolute;
  z-index: 1;
}

.noText, .yesText{
  padding: 5px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: whiteBasic;
  border-radius: 5px;
  width: fit-content;
  transition: .3s ease;
  cursor: pointer;
}

.noText{
  top: 50%;
  right: 0;
  transform: translateX(calc(100% + 40px)) translateY(calc(-50% - 16px));
  background: red;
  position: absolute;
  display: flex;
  align-items: center;

  &:before{
    content: '';
    width: 40px;
    height: 4px;
    background: grey1;
    flex-shrink: 0;
    position: absolute;
    transform: translateX(calc(-100% - 10px));
  }

  &:hover{
    background: grey1;
    color: red;
  }
}

.yesText{
  background: navy;
  margin: 0 auto;

  &:hover{
    background: grey1;
    color: blue;
  }
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
