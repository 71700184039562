@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value purple from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  position: relative;
}

.infoWrapper{
  padding: 0 60px;
  width: 100%;

  @media (max-width: 1024px){
    padding: 0 16px;
  }
}

.innerContainer{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.title{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 64px;
  line-height: 75px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 50px;

  @media (max-width: 1024px){
    margin-bottom: 50px;
    font-size: 44px;
    line-height: 51px;
  }

  @media (max-width: 600px){
    margin-bottom: 50px;
    font-size: 38px;
    line-height: 44px;
  }
}

.subTitle{
  max-width: 700px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;
  margin: 0 auto;
  margin-bottom: 53px;

  @media (max-width: 1024px){
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 600px){
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 19px;
  }
}

.buttonsBlock{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 53px;
  padding: 0 30px;

  @media (max-width: 768px){
    flex-direction: column;
  }

  @media (max-width: 600px){
    margin-bottom: 30px;
  }
}

.button{
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whiteBasic;
  border: 4px solid purple;
  box-sizing: border-box;
  border-radius: 50px;
  width: fit-content;
  padding: 20px 40px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;
  transition: .3s ease;

  @media (max-width: 1024px){
    padding: 15px 20px;
  }

  &:first-child{
    max-width: 238px;
  }

  &:nth-child(3){
    max-width: 181px;
  }

  &:last-child{
    max-width: 291px;
  }

  &:hover{
    background: blue;
    color: whiteBasic;
    border: 4px solid purple;
  }

  @media (max-width: 1024px){
    font-size: 18px;
    line-height: 21px;
    max-width: 190px;
  }

  @media (max-width: 768px){
    width: 190px;
  }
}

.arrowIcon{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    svg{
      width: 41px;
      height: auto;
    }
  }

  @media (max-width: 768px){
    transform: rotate(90deg);
    height: 61px;

    svg{
      width: auto;
    }
  }
}
