@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../assets/styles/abstracts/colors.scss';
@value red from '../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../assets/styles/abstracts/colors.scss';

.toolTip{
  cursor: text;
  width: 233px;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 14px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: blue;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: whiteBasic;

  &:after{
    content: '';
    width: 20px;
    height: 20px;
    transform: translateY(50%) rotate(45deg) scale(0.7);
    background: blue;
    flex-shrink: 0;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  }

  .tooltipText{
    width: 100%;
    background: blue;
    border-radius: 3px;
    padding: 7px;
    text-align: center;
    margin-bottom: -20px;
    position: relative;
    z-index: 1;
    white-space: pre-line;

    b{
      font-family: RobotoBold, Arial, sans-serif;
      font-weight: normal;
    }
  }
}
