@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';

.wrapper{
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.directionStart{
    .button{
      border-radius: 5px 0px 0px 5px;
    }
  }

  &.directionEnd{
    .button{
      border-radius: 0px 5px 5px 0px;
    }
  }
}

.button{
  background: navy;
  width: 260px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: whiteBasic;
  padding: 9px;
  position: relative;
  z-index: 4;
  cursor: pointer;

  &:hover{
    div{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.arrowsContainer{
  width: 100%;
  padding: 20px 0;
}

.arrowsContainer,
.arrowLeft,
.arrowRight,
.arrowIcon{
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowText{
  font-family: RobotoMediumItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: navy;
}

.arrowRight{
  width: 50%;
  margin-left: auto;
  transform: translateX(-16px);

  .arrowText{
    max-width: 66px;
    text-align: left;
  }

  .arrowIcon{
    margin-right: 10px;
  }

  &:not(:only-child){
    transform: translateX(0);
    width: calc(50% - 5px);
  }

  &.arrowRightFlip{
    .arrowIcon{
      transform: scaleY(-1)
    }
  }
}

.arrowLeft{
  width: 50%;
  margin-right: auto;
  transform: translateX(16px);

  .arrowText{
    max-width: 77px;
    text-align: right;
  }

  .arrowIcon{
    margin-left: 10px;
  }

  &:not(:only-child){
    transform: translateX(0);
    width: calc(50% - 5px);
  }

  &.arrowLeftFlip{
    .arrowIcon{
      transform: scaleY(-1)
    }
  }
}

.toolTip{
  cursor: text;
  max-width: 250px;
  width: 100vw;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 32px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: whiteBasic;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: grey2;

  .tooltipTail{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
  }

  .tooltipText{
    background: whiteBasic;
    padding: 8px;
    text-align: left;
    position: relative;
    z-index: 1;

    p, ul, li{
      margin: 0;
    }

    ul{
      padding-left: 24px;
    }

    li::marker{
      color: grey2;
    }

    a{
      color: blue;
      text-decoration: none;
    }
  }
}

.tooltipContent{
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
}

.tooltipTitle{
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: navy;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: whiteBasic;
  text-decoration: none;
}

.linkIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
