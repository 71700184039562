@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 660px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 440px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 280px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 63px 10px 60px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 50px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.3);
  }
}

.infoLeft, .infoRight{
  display: flex;
  align-items: center;
}

.infoLeft{
  margin-right: 46px;
}

.infoRight{
  margin-left: 46px;
}

.infoContent{
  max-width: 340px;
  width: 100%;
  background: whiteBasic;
  border-radius: 10px;
  padding: 20px;
}

.arrowsGroup{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child){
    margin-bottom: 16px;
  }
}

.singleIcon{
  &:not(:last-child){
    margin-bottom: 16px;
  }
}

.button{
  margin: 0 auto;
  max-width: 260px;
  width: 100%;
  position: relative;
  z-index: 4;

  &:not(:last-child){
    margin-bottom: 16px;
  }
}

.arrow{
  width: calc(50% - 10px);
}

.icon{
  width: 180px;
  margin-left: auto;
}

.legendsBlock{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.legend{
  max-width: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, Arial, sans-serif;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: navy;

  &:not(:last-child){
    margin-right: 10px;
  }

  &:nth-child(2){
    max-width: 137px;
  }

  .legendIcon{
    height: 30px;
  }

  svg{
    height: 100%;
    width: auto;

    path{
      fill: navy;
      &:last-child{
        display: none;
      }
    }
  }

  &.redColored{
    color: red;

    svg{
      path{
        fill: red;
      }
    }
  }
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
