@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../assets/styles/abstracts/colors.scss';
@value greyBorder from '../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../assets/styles/abstracts/colors.scss';
@value red from '../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.background{
  width: 100%;
  height: 100%;
  position: absolute;
}

.container{
  width: 100%;
  max-width: 672px;
  position: relative;
  z-index: 1;
  background: whiteBasic;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 768px){
    max-width: calc(100% - 32px);
  }
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: navy;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
    padding: 20px 16px;
  }
}

.closeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;

  svg{
    transition: .3s ease;
    transform: rotate(-90deg);

    path{
      transition: .3s ease;
    }
  }

  &:hover{
    svg{
      transform: rotate(0deg);

      path{
        fill: navy;
      }
    }
  }
}

.body{
  white-space: pre-line;
  border-top: 1px solid greyBorder;
  padding: 30px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: grey1;

  .blueText{
    color: blue;
  }

  .medium{
    font-family: RobotoMedium, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .bold{
    font-family: RobotoBold, Arial, sans-serif;
  }

  .quote{
    font-family: RobotoBoldItalic, Arial, sans-serif;
    color: grey3;
    margin-bottom: 20px;
    padding-left: 12px;
    position: relative;

    &:before{
      content: '';
      height: 100%;
      width: 0px;
      border: 2px solid grey3;
      border-radius: 2px;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }

  @media (max-width: 768px){
    padding: 20px 16px;
    font-size: 14px;
    line-height: 17px;
  }
}

.title{
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  margin: 20px 0 10px;
  color: blue;

  @media (max-width: 768px){
    font-size: 20px;
    line-height: 24px;
  }
}

.id{
  border-top: 1px solid greyBorder;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  min-height: 40px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: grey2;

  @media (max-width: 768px){
    padding: 10px 16px;
  }
}

.attentionBlock{
  border: 1px solid red;
  box-sizing: border-box;
  padding: 15px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: red;
  margin-bottom: 50px;

  @media (max-width: 768px){
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 17px;
  }
}

.attentionTitle{
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  font-family: RobotoBold, Arial, sans-serif;
  margin-bottom: 10px;

  @media (max-width: 768px){
    font-size: 20px;
    line-height: 24px;
  }
}

ul{
  list-style-type: disc;
  padding-left: 25px;
}

ol{
  padding-left: 25px;
}

ul, ol{
  margin: 0;

  li{
    margin: 0;
  }
}
