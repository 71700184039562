@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.toolTip {
  cursor: text;
  max-width: 255px;
  width: 100vw;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 14px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: grey2;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: whiteBasic;

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    transform: translateY(50%) rotate(45deg);
    background: grey2;
  }

  .tooltipText {
    background: grey2;
    padding: 7px;
    text-align: center;
    margin-bottom: -20px;
    position: relative;
    z-index: 1;
  }

  p, ul, li{
    margin: 0;
  }
}
