@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../assets/styles/abstracts/colors.scss';

.note{
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 452px;
  width: 100%;
  padding: 10px;
  background: whiteBasic;
  border: 1px dashed grey1;
  box-sizing: border-box;
  border-radius: 10px;

  &.noMargin{
    margin: 0;
  }

  .info{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-family: RobotoMedium, Arial, sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1em;
    color: grey1;
  }

  .text{
    width: 100%;
    font-family: RobotoLight, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: grey1;
  }

  strong{
    font-family: RobotoBold, Arial, sans-serif;
  }

  a{
    color: navy;
    text-decoration: none;
  }

  p, ul, li{
    margin: 0;
  }

  p{
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }

  ul{
    padding-left: 24px;
  }

  li::marker{
    color: grey1;
  }
}
