@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../assets/styles/abstracts/colors.scss';

.note{
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 600px;
  width: 100%;
  padding: 15px;
  background: whiteBasic;
  border: 1px solid grey5;
  box-sizing: border-box;
  border-radius: 10px;

  .info{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .text{
    padding-top: 4px;
    font-family: RobotoLight, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: grey1;
  }

  strong{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  b{
    font-family: RobotoMedium, Arial, sans-serif;
    font-weight: normal;
  }

  a{
    font-family: RobotoBold, Arial, sans-serif;
    color: blue;
    text-decoration: underline;
  }

  p, ul, ol, li{
    margin: 0;
  }

  ul, ol{
    padding-left: 24px;
  }

  li::marker{
    color: grey1;
  }

  &.themeBlue{
    .note{
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      max-width: 600px;
      width: 100%;
      padding: 15px;
      background: whiteBasic;
      border: 1px solid grey5;
      box-sizing: border-box;
      border-radius: 10px;

      .info{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      .text{
        padding-top: 4px;
        font-family: RobotoLight, Arial, sans-serif;
        font-size: 14px;
        line-height: 16px;
        color: grey1;
      }

      a{
        font-family: RobotoBold, Arial, sans-serif;
        color: blue;
        text-decoration: underline;
      }

      p, ul, li{
        margin: 0;
      }

      ul{
        padding-left: 24px;
      }

      li::marker{
        color: blue;
      }
    }
  }
}
