@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
}

.button{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid navy;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 7px;
  user-select: none;
  position: relative;
  z-index: 4;

  &.buttonBlue{
    color: whiteBasic;
    background: navy;
    padding: 5px 7px;
    transition: .3s ease;

    &:hover{
      background: blue;
      border: 3px solid blue;
    }
  }

  &.buttonWhite{
    color: navy;
    background: transparent;
    margin-right: 20px;
    transition: .3s ease;

    svg{
      path{
        fill: navy;
        transition: .3s ease;
      }
    }

    &:hover{
      background: blue;
      color: whiteBasic;

      svg{
        path{
          fill: whiteBasic;
        }
      }
    }
  }
}

.icon{
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconRotated {
  transform: rotate(180deg);
}

.dropDown{
  position: absolute;
  bottom: 0px;
  top: 60px;
  max-width: 392px;
  background-color: whiteBasic;
  border: 1px solid greyBackground;
  pointer-events: none;
  height: max-content;
  width: 100%;
  margin: 0 auto;
  max-height: 0px;
  overflow: hidden;
  z-index: 4;
}

.dropDownOpen{
  border: 1px solid grey4;
  max-height: max-content;
  pointer-events: visible;
}

.dropDownItem{
  color: grey2;
  padding: 5px 10px;
  &:not(:last-child){
    border-bottom: 1px solid grey4;
    cursor: pointer;
  }
  &:hover{
    background-color: navy;
    color: whiteBasic;
    transition: all 0.3s ease;
    .dropDownItemSubTitle{
      color: whiteBasic;
    }
  }
}

.dropDownItemTitle{
  margin: 0;
  font-family: RobotoMedium, Arial, sans-serif;
}

.dropDownItemSubTitle{
  color: grey3;
  transition: color 0.3s ease;
  font-size: 12px;
}

.buttonWrapper{
  position: relative;

  &.open{
    margin-bottom: 270px;
  }
}
