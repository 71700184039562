@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root {
    width: 100%;
}

.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.title {
    font-family: RobotoMedium, Arial, sans-serif;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.1em;
    color: grey1;
    margin-bottom: 8px;

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
    }
}

.subTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: RobotoLight, Arial, sans-serif;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: grey1;

    @media (max-width: 768px) {
        flex-direction: column;
        font-size: 18px;
        line-height: 21px;
    }
}

.isoLabel {
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 26px;
    background: yellowBasic;
    border-radius: 20px;
    padding: 3px 10px;
    font-family: RobotoMedium, Arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    color: navy;

    @media (max-width: 768px) {
        margin-top: 16px;
        margin-left: 0;
    }
}

.eyeIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.mobileWrapper{
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px){
        height: 710px;
        width: calc(100vw - 120px);
    }

    @media (max-width: 768px){
        height: 500px;
        width: calc(100vw - 80px);
    }

    @media (max-width: 600px){
        height: 270px;
    }
}

.infoWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    padding: 53px 10px 50px;

    @media (max-width: 1024px){
        transform: scale(0.75);
    }

    @media (max-width: 768px){
        padding: 59px 0 50px;
        transform: scale(0.5);
        transform-origin: 50% 50%;
    }

    @media (max-width: 600px){
        transform: scale(0.25);
    }
}

.topWrapper {
    background: whiteBasic;
    border: 1px solid grey3;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 70px;
    max-width: 940px;
}

.topLabel {
    margin-bottom: 50px;
    font-family: RobotoMedium, Arial, sans-serif;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: grey1;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
}

.headerTitle {
    font-family: Roboto, Arial, sans-serif;
    font-size: 25.2853px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: grey1;
    padding: 8px 13px;
    min-height: 48px;
    background: whiteBasic;
    border: 2px solid grey1;
    box-sizing: border-box;
    border-radius: 35.1185px;
}

.connection {
    transform: translateY(5px) translateX(50px);
    margin-bottom: -10px;
    cursor: pointer;
    position: relative;
    z-index: 3;

    .tooltipArrow{
        right: -80%;
        top: 0;
        transform: translateY(-44px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 0;
    }

    &:hover{
        .tooltipArrow{
            div{
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}

.mainContent {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.itemWrapper {
    margin-right: 10px;
    display: flex;
    min-height: 360px;
    flex-direction: column;
    justify-content: space-between;
    &:first-child {
        margin-right: 35px;
    }
}
.stepsBlock {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 32px;
}

.stepText {
    display: flex;
    align-items: center;
    min-height: 42px;
    min-width: 65px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: grey1;
    font-family: RobotoMedium, Arial, sans-serif;
}

.horizontalArrow {
    flex-shrink: 0;
    & svg {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &:nth-of-type(3) {
        transform: rotate(180deg);
    }
}

.bottomWrapper{
    margin: 50px 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 942px;
}

.scrollWrapperTablet{
    @media (max-width: 1024px){
        margin: 30px 0;
    }
}
