@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  position: relative;
}

.infoWrapper{
  padding: 0 60px;
  width: 100%;

  @media (max-width: 1024px){
    padding: 0 16px;
  }
}

.innerContainer{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.title{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 64px;
  line-height: 75px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 10px;

  @media (max-width: 1024px){
    margin-bottom: 10px;
    font-size: 44px;
    line-height: 51px;
  }

  @media (max-width: 600px){
    margin-bottom: 10px;
    font-size: 38px;
    line-height: 44px;
  }
}

.subTitle{
  max-width: 700px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;
  margin: 0 auto;
  margin-bottom: 53px;

  .bold{
    font-family: RobotoBold, Arial, sans-serif;
  }

  @media (max-width: 1024px){
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 600px){
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 19px;
  }
}

.buttonsBlock{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 53px;

  @media (max-width: 750px){
    flex-direction: column;
  }

  @media (max-width: 600px){
    margin-bottom: 30px;
  }
}

.button{
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whiteBasic;
  border: 4px solid blue;
  box-sizing: border-box;
  border-radius: 50px;
  max-width: 220px;
  padding: 20px 40px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.02em;
  color: blue;
  transition: .3s ease;
  position: relative;
  z-index: 4;

  @media (max-width: 1024px){
    padding: 15px 20px;
  }

  &:hover{
    background: blue;
    color: whiteBasic;
    border: 4px solid blue;
  }

  &.buttonComplex{
    min-height: 106px;
    padding: 0;
    max-width: 290px;
    flex-direction: column;
    border: 3px solid blue;
    overflow: hidden;

    &:hover{
      background: whiteBasic;
      color: blue;
      border: 3px solid blue;

      .top{
        background: blue;
        color: whiteBasic;
      }
    }

    @media (max-width: 1024px){
      padding: 0;
      max-width: 200px;
      min-height: 75px;
    }
  }

  @media (max-width: 1024px){
    max-width: 150px;
    height: 75px;
    font-size: 18px;
    line-height: 21px;
  }
}

.arrowIcon{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px){
    transform: rotate(90deg);
    height: 90px;
  }
}

.top{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: whiteBasic;
  color: blue;
  transition: .3s ease;
}

.bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: blue;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: whiteBasic;
  padding: 0 36px;
  text-align: left;

  @media (max-width: 1024px){
    font-size: 14px;
    line-height: 15px;
    padding: 0 26px;
  }
}

.flagIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.linkHover{
  width: fit-content;
  position: relative;
  z-index: 10;
  cursor: pointer;
  font-family: RobotoBold, Arial, sans-serif;
  color: blue;
  text-decoration: underline;

  &:hover{
    div {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
