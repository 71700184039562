@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';

.wrapper{
  max-width: 100%;
  display: flex;
  align-items: stretch;

  &.reversed{
    flex-direction: row-reverse;
  }
}

.arrowBlock{
  display: flex;
  align-items: center;
  position: relative;
}

.arrowText{
  position: absolute;
  z-index: 1;
  left: 10px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: whiteBasic;
}

.stepEndBlock{
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
}

.endText{
  white-space: nowrap;
  width: fit-content;
  display: flex;
  align-items: center;
  font-family: RobotoBoldItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: grey2;
  transform: rotate(-90deg);

  &:before,
  &:after{
    content: '';
    flex: 1;
    border-top: 2px dotted grey1;
    width: 38px;
  }

  &:before{
    margin-right: 12px;
  }

  &:after{
    margin-left: 12px;
  }
}

.stepReviewBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviewText{
  margin-top: 10px;
  font-family: RobotoBoldItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: grey2;
  max-width: 70px;
}
