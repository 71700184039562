@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value blackBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';

.wrapper{
  max-width: 100%;
  display: flex;
  align-items: stretch;

  &.grey{
    svg{
      path{
        fill: grey2;
      }
    }

    .blockTitle,
    .blockText{
      color: grey2;
    }
  }
}

.block{
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:last-child){
    margin-right: 30px;
  }
}

.blockTitle{
  font-family: RobotoBoldItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: blackBasic;
  margin-bottom: 10px;
}

.blockContent{
  display: flex;
  align-items: flex-start;
}

.blockIcon{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 10px;
}

.blockText{
  font-family: RobotoItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: grey1;
}

.blockTextContent{
  min-height: 62px;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.example{
  border: 1px dashed grey3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-family: RobotoItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: grey1;
}

.exampleTitle{
  font-family: RobotoBold, Arial, sans-serif;
  margin-bottom: 10px;
}
