@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value red from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
  border-top: 3px solid whiteBasic;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background: greyBackground;
}

.footerWrapper{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 45px 0 60px;

  @media (max-width: 768px){
    padding: 0 10px;
    height: 70px;
  }
}

.link{
  padding: 0 5px;
  cursor: pointer;
  border-radius: 3px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: blue;
  text-decoration: none;
  width: fit-content;
  height: 30px;
  background: transparent;
  transition: .3s ease;

  svg{
    path{
      fill: blue;
      transition: .3s ease;
    }
  }

  &:hover{
    color: whiteBasic;
    background: blue;

    svg{
      path{
        fill: whiteBasic;
      }
    }
  }

  &:not(:last-child){
    margin-right: 37px;

    @media (max-width: 768px){
      margin-right: 17px;
    }

    @media (max-width: 600px){
      margin-right: 0px;
    }
  }

  @media (max-width: 768px){
    font-size: 14px;
    line-height: 16px;
    width: fit-content;
    padding: 4px 6px;
    height: auto;

    svg{
      height: 16px;
      width: auto;
    }
  }

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (max-width: 450px) {
    font-size: 10px;
    line-height: 12px;
    padding: 4px 4px;

    &:first-child{
      white-space: nowrap;
    }
  }
}

.linkIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  @media (max-width: 768px){
    margin-right: 5px;
  }
}

.socials{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 50px;

  @media (max-width: 768px){
    margin-right: 20px;
  }

  @media (max-width: 600px){
    margin-right: 0px;
  }
}

.icon{
  border-radius: 3px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: .3s ease;

  svg{
    path{
      fill: blue;
      transition: .3s ease;
    }
  }

  &:hover{
    background: blue;

    svg{
      path{
        fill: whiteBasic;
      }
    }
  }

  &:not(:last-child){
    margin-right: 20px;

    @media (max-width: 768px){
      margin-right: 10px;
    }

    @media (max-width: 600px){
      margin-right: 0;
    }
  }

  @media (max-width: 768px){
    width: 30px;
    height: 30px;

    svg{
      height: 16px;
      width: auto;
    }

    &:hover{
      background: transparent;

      svg{
        path{
          fill: blue;
        }
      }
    }
  }

  @media (max-width: 425px){
    width: 24px;
    height: 24px;

    svg{
      height: 14px;
      width: auto;
    }
  }
}

.pageId{
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: grey3;

  @media (max-width: 450px) {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: normal;
  }
}
