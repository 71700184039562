@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../assets/styles/abstracts/colors.scss';
@value greyBorder from '../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.background{
  width: 100%;
  height: 100%;
  position: absolute;
}

.container{
  width: fit-content;
  max-width: 750px;
  position: relative;
  z-index: 1;
  background: whiteBasic;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 768px){
    max-width: calc(100% - 32px);
    max-height: 90vh;
  }
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: navy;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
    padding: 20px 16px;
  }
}

.titleBlock{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  @media (max-width: 375px){
    flex-direction: column;
    align-items: flex-start;
  }
}

.title{
  margin-right: auto;
}

.isoLabel{
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 375px){
    margin-top: 5px;
  }
}

.closeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  cursor: pointer;

  svg{
    transition: .3s ease;
    transform: rotate(-90deg);

    path{
      transition: .3s ease;
    }
  }

  &:hover{
    svg{
      transform: rotate(0deg);

      path{
        fill: navy;
      }
    }
  }

  @media (max-width: 768px){
    margin-left: 20px;
  }
}

.body{
  padding: 30px;
  border-top: 1px solid greyBorder;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey2;

  @media (max-width: 768px){
    padding: 20px 16px;
  }
}

.imageWrapper{
  width: 100%;
}

.image{
  width: 100%;
  height: auto;
}

.id{
  border-top: 1px solid greyBorder;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  min-height: 40px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: grey2;

  @media (max-width: 768px){
    padding: 10px 16px;
  }
}

.caption{
  padding: 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey2;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 19px;
  }
}
