@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  position: relative;
}

.infoWrapper{
  padding: 0 60px;
  width: 100%;

  @media (max-width: 768px){
    padding: 0 40px;
  }
}

.cardsWrapper{
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 600px;
  flex-shrink: 0;
  transform: scale(0.715);
  height: 220px;
  transform-origin: 0 0;

  @media (max-width: 1024px){
    transform: scale(1);
    width: 100%;
    height: auto;
  }
}

.infoBlock {
  width: 100%;
  position: relative;
  max-width: 240px;

  &:nth-child(1),
  &:nth-child(2) {
    margin-bottom: 120px;
  }

  &:nth-child(1),
  &:nth-child(3) {
    margin-right: 120px;
  }

  &.disabled{
    opacity: 0.2;
    pointer-events: none;
  }

  @media (max-width: 1024px){
    max-width: calc((100% - 120px) / 2);
  }

  @media (max-width: 600px){
    max-width: calc((100% - 100px) / 2);

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 100px;
    }

    &:nth-child(1),
    &:nth-child(3) {
      margin-right: 100px;
    }
  }

  @media (max-width: 425px){
    max-width: calc((100% - 70px) / 2);

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 70px;
    }

    &:nth-child(1),
    &:nth-child(3) {
      margin-right: 70px;
    }
  }
}

.mainContent{
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 90px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  background: whiteBasic;
  color: navy;
  border: 4px solid navy;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s ease;

  &:hover{
    background: navy;
    color: whiteBasic;
  }

  @media (max-width: 600px){
    font-size: 14px;
    line-height: 17px;
    padding: 6px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
    line-height: 14px;
  }
}

.marginBottom{
  margin-bottom: 35px;

  @media (max-width: 1024px){
    margin-bottom: 0;
  }
}

