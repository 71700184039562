@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value red from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.contentWrapper{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.termContainer{
  width: 100%;

  &:not(:last-child){
    margin-bottom: 10px;
  }
}

.term{
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  background: greyBackground;
  border-radius: 20px;

  .col1{
    font-family: RobotoMedium, Arial, sans-serif;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
  }

  .col2{
    white-space: pre-line;
  }

  @media (max-width: 1024px){
    flex-direction: column;

    .col2,
    .col3,
    .col4{
      font-family: RobotoMedium, Arial, sans-serif;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: normal;
    }

    .col1{
      font-family: RobotoMedium, Arial, sans-serif;
      font-size: 21px;
      line-height: 24px;

      .colTitleMobile{
        line-height: 24px;
      }
    }
  }

  @media (max-width: 768px){
    .col1{
      font-family: RobotoMedium, Arial, sans-serif;
      font-size: 18px;
      line-height: 21px;

      .colTitleMobile{
        line-height: 21px;
      }
    }
  }
}

.root{
  max-width: 1000px;
  margin: 0 auto;
}

.header{
  width: 100%;
  border-bottom: 1px solid grey1;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: grey1;
}

.colTitleMobile{
  display: none;

  @media (max-width: 1024px){
    flex-shrink: 0;
    display: flex;
    width: 130px;
    margin-right: 20px;
    font-family: RobotoBold, Arial, sans-serif;
    font-size: 16px;
    line-height: 19px;
  }

  @media (max-width: 768px){
    margin-right: 10px;
    width: 100px;
  }
}

.col1{
  width: 22%;
  max-width: 210px;
  padding: 10px;

  @media (max-width: 1024px){
    display: flex;
    width: 100%;
    max-width: 100%;
  }
}

.col2{
  width: 44%;
  max-width: 430px;
  padding: 10px;

  @media (max-width: 1024px){
    display: flex;
    width: 100%;
    max-width: 100%;
  }
}

.col3{
  width: 20%;
  max-width: 200px;
  padding: 10px;

  @media (max-width: 1024px){
    display: flex;
    width: 100%;
    max-width: 100%;
  }
}

.col4{
  width: 14%;
  max-width: 140px;
  padding: 10px;

  @media (max-width: 1024px){
    display: flex;
    width: 100%;
    max-width: 100%;
  }
}

.header{
  .col1,
  .col4 {
    cursor: pointer;
  }

  .col1, .col2, .col3, .col4{
    display: flex;
    align-items: center;
  }

  @media (max-width: 1024px){
    .col2,
    .col3{
      display: none;
    }

    .col1,
    .col4{
      width: fit-content;

      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 600px){
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;

    .col1,
    .col4{
      &:not(:last-child){
        margin-right: 0;
      }
    }
  }

  @media (max-width: 360px){
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
  }
}

.mobileSort{
  display: none;

  @media (max-width: 1024px){
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: auto;
  }
}

.sortingIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
}

.pageLink{
  background: yellowBasic;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.05em;
  color: navy;
  padding: 10px;
  cursor: pointer;
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  margin-left: auto;

  @media (max-width: 1024px){
    font-size: 24px;
    line-height: 28px;
    width: 60px;
    height: 60px;
  }
}

.topButton{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: grey3;
  transition: .3s ease;

  svg{
    path{
      transition: .3s ease;
    }
  }

  &:hover{
    color: navy;

    svg{
      path{
        stroke: navy;
      }
    }
  }
}

.iconTop{
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover{
    div{
      opacity: 1;
    }
  }
}
