@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../assets/styles/abstracts/colors.scss';
@value red from '../../../assets/styles/abstracts/colors.scss';
@value greyBackgroundLight from '../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.25));
  background: whiteBasic;

  @media (max-width: 1024px) {
    margin: 30px auto 0;
  }

  @media (max-width: 768px){
    margin: 0 auto;
  }
}

.top{
  padding: 50px 50px 20px;
  border-bottom: 1px solid grey4;

  @media (max-width: 768px){
    padding: 20px 16px;
  }
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: navy;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  margin-top: 6px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: grey3;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
  }
}

.bottom{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: grey1;
  padding: 20px 50px 50px;
  display: none;

  &.active{
    display: block;
  }

  @media (max-width: 768px){
    padding: 20px 16px;
  }
}

.section1Heading{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: grey2;
  margin-bottom: 10px;

  &.bottomTitleBlue{
    color: navy;
  }

  &.bottomTitleCenter{
    padding: 40px;

    @media (max-width: 768px){
      padding: 0;
    }
  }

  b{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
    padding: 0;
  }
}

.blocks{
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .block{
    flex: 1;
    max-width: calc(50% - 20px);

    &.mainTextBig{
      .text{
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        font-family: RobotoLight, Arial, sans-serif;
      }
    }
  }

  &.withBorder{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid grey4;
  }

  &:last-child{
    margin-bottom: 0;
  }

  @media (max-width: 1024px){
    flex-direction: column;
  }
}

.blockFull{
  margin-bottom: 20px;
  width: 100%;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;
  white-space: pre-line;

  b{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  ul, ol{
    margin: 0;

    li{
      margin: 0;
    }
  }

  &.blockFullMarginTop{
    margin-top: 20px;
  }

  &:last-child{
    margin-bottom: 0;
  }
}

.text{
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;
  margin-bottom: 20px;

  b{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  p{
    &:not(:first-child){
      margin-top: 18px;
    }
  }

  ul, ol{
    margin: 0;

    li{
      margin: 0;
    }
  }

  &:only-child{
    margin-bottom: 0;
  }

  @media (max-width: 1024px){
    margin-bottom: 0;
  }
}


.examplesTitle{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: grey2;

  &:not(:last-child){
    margin-bottom: 10px;
  }

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
  }
}

.examples{
  padding: 20px 0;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;

  &:first-child{
    border-top: none;
    padding-top: 0;
  }
}

.examplesSingle{
  padding-top: 20px;
}

.examplesMultiple{
  div{
    &:not(:last-child){
      margin-bottom: 40px;
    }
  }

  .exampleBlock{
    border: 1px solid navy;
  }
}

.exampleBlock{
  padding: 30px;

  @media (max-width: 768px){
    padding: 10px;
  }
}

.example{
  width: fit-content;
  max-width: 100%;
}

.exampleImage{
  max-width: 100%;
}

.marker{
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: grey1;
  margin-bottom: 10px;
}

.exampleTitle{
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: navy;
  margin-bottom: 30px;

  b{
    font-family: RobotoBold, Arial, sans-serif;
    font-weight: normal;
  }

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
  }
}

.switcher{
  cursor: pointer;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: grey2;

  &:not(:last-child){
    margin-bottom: 10px;
  }
}

.detailsText{
  margin-top: 10px;
  padding: 20px;
  border: 1px solid blue;

  .blocks{
    margin-bottom: 0;

    .block{
      b{
        font-family: RobotoBold, Arial, sans-serif;
        font-weight: normal;
      }

      ul, ol{
        margin: 0;

        li{
          margin: 0;
        }
      }
    }
  }

  img{
    max-width: 100%;
  }

  @media (max-width: 1024px){
    padding: 10px;
    flex-direction: column;

    .block{
      display: flex;
      flex: 1;
      max-width: calc(100%);
    }
  }
}

.bottomBlock{
  display: flex;
  align-items: stretch;

  @media (max-width: 1024px){
    flex-direction: column;

    .seeMore{
      max-width: calc(100%);
      margin-right: 0;

      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
  }
}

.seeMore{
  flex: 1;
  max-width: 334px;
  margin-right: 20px;
  background: grey5;
  border-radius: 5px;
  padding: 15px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: grey1;
}

.seeMoreTitle{
  font-family: RobotoBold, Arial, sans-serif;
  margin-bottom: 10px;
}

ul{
  list-style-type: disc;
  padding-left: 25px;
}

ol{
  padding-left: 25px;
}

iframe{
  width: 100%;
  min-height: 600px;
}

.mainTextBig{
  .blockFull{
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    font-family: RobotoLight, Arial, sans-serif;
  }
}

.tabsContainer{
  width: 100%;
  margin-top: 20px;
  padding: 0 50px;

  @media (max-width: 768px){
    padding: 0 16px;
  }
}

.tabs{
  width: 100%;
  display: flex;
  align-items: stretch;
  border-bottom: 3px solid blue;
}

.tab{
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 24px;
  line-height: 18px;
  color: grey1;
  padding: 10px;
  background: greyBackgroundLight;
  cursor: pointer;
  transition: .3s ease;

  &.active{
    font-family: RobotoBold, Arial, sans-serif;
    background: blue;
    color: whiteBasic;
  }

  @media (max-width: 768px){
    font-size: 14px;
    line-height: 18px;
  }
}
