@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  position: relative;
}

.infoWrapper{
  padding: 0 60px;
  width: 100%;

  @media (max-width: 1024px){
    padding: 0 16px;
  }
}

.innerContainer{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.title{
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 64px;
  line-height: 75px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 10px;

  @media (max-width: 1024px){
    margin-bottom: 10px;
    font-size: 44px;
    line-height: 51px;
  }

  @media (max-width: 600px){
    margin-bottom: 10px;
    font-size: 38px;
    line-height: 44px;
  }
}

.subTitle{
  max-width: 700px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;
  margin: 0 auto;
  margin-bottom: 53px;

  @media (max-width: 1024px){
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 600px){
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 19px;
  }
}

.buttonsBlock{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 53px;
  padding: 0 30px;

  @media (max-width: 1100px){
    height: 800px;
  }

  @media (max-width: 900px){
    height: 700px;
  }

  @media (max-width: 780px){
    height: 600px;
  }

  @media (max-width: 630px){
    height: 500px;
  }

  @media (max-width: 530px){
    height: 400px;
  }

  @media (max-width: 425px){
    height: 300px;
  }
}

.buttonContainer{
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px){
    transform-origin: 50% 50%;
    transform: scale(0.8);
  }

  @media (max-width: 900px){
    transform: scale(0.7);
  }

  @media (max-width: 780px){
    transform: scale(0.6);
  }

  @media (max-width: 630px){
    transform: scale(0.5);
  }

  @media (max-width: 530px){
    transform: scale(0.4);
  }

  @media (max-width: 425px){
    transform: scale(0.3);
  }
}

.buttonWrapper{
  display: flex;
  flex-direction: column;
  position: relative;

  &.buttonWrapper1{
    .buttonsBlockSmall{
      transform: translateX(122px);

      .button{
        margin-bottom: 25px;
        width: 160px;
        height: 71px;
      }
    }

    .arrowIcon{
      left: 51px;
      top: 71px;
    }
  }

  &.buttonWrapper2{
    height: 96px;
    flex-direction: row;

    .buttonsBlockSmall{
      transform: translateX(78px) translateY(-120px);

      .button{
        margin-bottom: 25px;
        width: 270px;
        padding: 10px 20px;
      }
    }

    .arrowIcon{
      left: 160px;
      top: unset;
      bottom: 60px;
    }
  }

  &.buttonWrapper3{
    height: 96px;
    flex-direction: row;

    .buttonsBlockSmall{
      transform: translateX(78px) translateY(25px);

      .button{
        margin-bottom: 25px;
        width: 270px;
        padding: 10px 20px;
      }
    }

    .arrowIcon{
      left: 160px;
      top: 0px;
    }
  }

  &.buttonWrapper4{
    .buttonsBlockSmall{
      transform: translateX(122px);

      .button{
        width: 270px;
        padding: 10px 20px;
      }
    }

    .arrowIcon{
      left: 51px;
      top: 71px;
    }
  }
}

.buttonsBlockSmall{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 80px;

  .button{
    min-height: 71px;
    width: 245px;
  }
}

.buttonsBlockFirst{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 120px;
  position: relative;
  transform: translateY(-275px);

  .button{
    font-size: 28px;
    line-height: 33px;
    background: yellowBasic;
    color: grey1;
  }

  .arrowIcon{
    left: 207px;
    transform: translateY(calc(-10px));
  }
}

.button{
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whiteBasic;
  border: 4px solid yellowBasic;
  box-sizing: border-box;
  border-radius: 50px;
  max-width: 270px;
  min-height: 71px;
  padding: 10px 30px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;
  transition: .3s ease;
  position: relative;
  z-index: 4;

  &:not(:last-child){
    margin-bottom: 25px;
  }

  &:hover{
    background: blue;
    color: whiteBasic;
    border: 4px solid yellowBasic;
  }
}

.arrowIcon{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(4){
    right: 195px;
    top: 110px;
  }
}

.arrowIconMobile {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
