@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';

.arrow{
  width: 100%;
  display: flex;
  align-items: center;

  &.arrowLeft{
    flex-direction: row;
    justify-content: flex-end;

    .arrowIcon, .arrowWrapper, .hover{
      justify-content: flex-start;
    }

    .text{
      text-align: right;
      margin-right: 10px;
    }
  }
  &.arrowRight{
    flex-direction: row-reverse;
    justify-content: flex-start;

    .arrowIcon, .arrowWrapper, .hover{
      justify-content: flex-end;
    }

    .text{
      text-align: left;
      margin-left: 10px;
    }
  }

  &.blueColor{
    .text{
      color: blue;
    }

    svg{
      path{
        fill: blue;
      }
    }

    .icon{
      svg{
        path{
          fill: navy;
        }
      }
    }
  }

  &.blueIcon{
    svg{
      path{
        fill: navy;
      }
    }
  }

  &.redColor{
    .text{
      color: red;
    }

    svg{
      path{
        fill: red;
      }
    }
  }

  &.greenColor{
    .text{
      color: blue;
    }

    svg{
      path{
        fill: green;
      }
    }
  }

  &.redIcon{
    svg{
      path{
        fill: red;
      }
    }
  }

  &.redBlueColor{
    .text{
      color: blue;
    }

    svg{
      path{
        fill: red;
      }
    }
  }

  &.noHover{
    pointer-events: none;

    .arrowWrapper{
      cursor: auto;
    }
  }

  &:hover{
    .text{
      color: navy;
      font-family: RobotoMedium, Arial, sans-serif;
      font-size: 11.5px;
      line-height: 14px;
    }

    .hover{
      opacity: 1;

      svg{
        path{
          fill: navy
        }
      }
    }
  }
}

.text{
  font-family: RobotoLightItalic, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: grey2;
  transition: color .3s ease;
}

.arrowIcon{
  width: 53px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.arrowWrapper{
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}

.hover{
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: .3s ease;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  svg{
    path{
      fill: navy
    }
  }
}

.icon{
  width: 87px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
