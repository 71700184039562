@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';
@value red from '../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../assets/styles/abstracts/colors.scss';

.root{
  background: greyBackground;
  width: 100%;
  position: relative;
  z-index: 15;
  height: 101px;
  flex-shrink: 0;
  transition: .3s linear;

  &.searchVisible{
    height: 181px;
  }

  &.headerSmall{
    height: 61px;

    .link{
      font-size: 14px;
      line-height: 15px;
      height: 25px;
    }

    .middleLinks{
      height: 35px;
    }

    .logoLeft, .logoRight{
      height: 28px;

      .logo{
        display: none;
      }

      .logoSmall{
        display: flex;
      }
    }

    &.searchVisible{
      height: 141px;
    }

    .headerWrapper{
      height: 61px;
    }

    .mobileMenu{
      height: calc(100vh - 61px);
    }
  }

  @media (max-width: 768px){
    height: 61px;

    &.searchVisible{
      height: 141px;
    }
  }
}

.headerContainer{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background: whiteBasic;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.headerWrapper{
  background: whiteBasic;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px 0 50px;
  transition: .3s linear;

  &.noSearch{
    padding: 0 120px 0 130px;

    @media (max-width: 1300px){
      padding: 0 40px 0 50px;
    }

    @media (max-width: 768px){
      padding: 0 16px;
    }
  }

  @media (max-width: 768px){
    height: 61px;
    padding: 0 16px;
  }
}

.logoLeft{
  margin-right: 20px;
  width: 90px;
  cursor: pointer;
  height: 60px;

  @media (max-width: 768px){
    height: 28px;

    .logo{
      display: none;
    }

    .logoSmall{
      display: flex;
    }
  }

  @media (max-width: 425px){
    display: none;
  }
}

.logoRight{
  width: 145px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
  height: 60px;

  @media (max-width: 768px){
    height: 28px;

    .logo{
      display: none;
    }

    .logoSmall{
      display: flex;
    }
  }
}

.logo{
  height: 100%;
  width: auto;
}

.logoSmall{
  height: 100%;
  width: auto;
  display: none;
}

.menuWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  transform: translateX(26px);

  @media (max-width: 1300px){
    transform: translateX(20px);
  }

  @media (max-width: 1100px){
    transform: translateX(40px);
  }

  @media (max-width: 425px){
    justify-content: flex-start;
    transform: none;
  }
}

.link{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: navy;
  text-decoration: none;
  transition: .3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  padding: 0 10px;
  border-radius: 20px;
  height: 30px;

  &:hover{
    background: navy;
    color: whiteBasic;
  }

  &:active{
    background: red;
    color: whiteBasic;

    @media (max-width: 1100px){
      background: transparent;
      color: navy;
    }
  }

  @media (max-width: 1100px){
    display: none;
  }
}

.middleLinks{
  margin-left: 35px;
  margin-right: 35px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid blue;
  border-radius: 50px;
  height: 40px;

  .link{
    height: 100%;
    width: fit-content;

    &:not(:last-child){
      margin-right: 10px;
    }
  }

  &.middleLinksLeft{
    margin-right: 15px;
    padding: 5px;
    width: 316px;
  }

  &.middleLinksRight{
    margin-left: 15px;
    padding: 5px;
    width: 232px;
  }

  @media (max-width: 1300px){
    margin-left: 25px;
    margin-right: 25px;

    &.middleLinksLeft {
      margin-right: 5px;
    }

    &.middleLinksRight {
      margin-left: 5px;
    }
  }

  @media (max-width: 1100px){
    display: none;
  }
}

.searchButton{
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 50px;
  height: 30px;
  border-radius: 20px;
  transition: background-color .3s ease;

  svg{
    svg{
      path{
        fill: navy;
      }
    }
    transition: .3s ease;
  }

  &:hover{
    background: navy;

    .searchIcon{
      svg{
        path{
          fill: whiteBasic;
        }
      }
    }
  }

  &:active{
    background: red;

    .searchIcon{
      svg{
        path{
          fill: whiteBasic;
        }
      }
    }
  }

  @media (max-width: 1300px){
    margin-left: 21px;
  }
}

.searchIcon{
  cursor: pointer;
  transform: translateY(2px);
}

.searchContainer{
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 15;
  border-top: 1px solid grey4;
  background: whiteBasic;
  width: 100%;
  transition: .3s ease-in;

  &.searchVisible{
    overflow: unset;
    height: 80px;
    transition: .3s ease-out;

    .searchWrapper{
      opacity: 1;
      transition: .2s ease .1s;
    }
  }

  @media (max-width: 768px){
    height: 0;

    &.searchVisible{
      height: 70px;
    }
  }
}

.searchWrapper{
  opacity: 0;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: .2s ease 0s;

  @media (max-width: 768px){
    padding: 0 16px;
  }
}

.close, .back{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
  position: relative;

  &:hover{
    div{
      opacity: 1;
    }
  }
}

.inputWrapper{
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 40px;

  .searchIcon{
    position: absolute;
    left: 13px;
  }
}

.input{
  background: greyBackground;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  outline: none;
  font-family: RobotoRegular, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: navy;
  border: none;
  padding: 5px 15px 5px 44px;
}

.input::placeholder{
  font-family: RobotoLight, Arial, sans-serif;
  color: grey2;
}

.burgerButton{
  display: none;

  @media (max-width: 1100px){
    display: flex;
  }

  .open{
    height: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .line{
    background: navy;
    height: 2px;
    width: 14px;

    &:not(:last-child){
      margin-bottom: 2px;
    }
  }

  .close{
    margin-left: 0;
    display: none;
  }

  &.opened{
    .open{
      display: none;
    }

    .close{
      display: flex;
    }
  }
}

.mobileMenu{
  position: fixed;
  top: 106px;
  left: 0;
  height: calc(100vh - 101px);
  width: 100%;
  background: whiteBasic;
  overflow-y: auto;
  padding: 80px 16px;
  display: none;
  z-index: 10;
  transition: .3s linear;

  @media (max-width: 1100px){
    &.opened{
      display: flex;
      flex-direction: column;
      align-items: center;

      .link{
        height: auto;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 768px){
    top: 61px;
    height: calc(100vh - 61px);
  }

  .link, .middleLinks{
    display: flex;
  }

  .middleLinks{
    border-radius: 40px;
    width: fit-content;
    flex-direction: column;
    align-items: center;

    .link{
      width: 100%;
      padding: 10px 16px;

      &:first-child{
        padding: 16px 16px 10px;
        border-radius: 40px 40px 0px 0px;
      }

      &:last-child{
        padding: 10px 16px 16px;
        border-radius: 0px 0px 40px 40px;
      }

      &:not(:last-child){
        margin-right: 0px;
      }
    }

    &.middleLinksTop{
      height: 164px;
      margin: 22px auto 0;
    }

    &.middleLinksBottom{
      height: 112px;
      margin: 22px auto;
    }
  }
}
