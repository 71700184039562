@value whiteBasic from '../../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../../assets/styles/abstracts/colors.scss';
@value red from '../../../../assets/styles/abstracts/colors.scss';
@value green from '../../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100%;
}

.container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.1em;
  color: grey1;
  margin-bottom: 8px;

  @media (max-width: 768px){
    font-size: 24px;
    line-height: 28px;
  }
}

.subTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: grey1;

  @media (max-width: 768px){
    flex-direction: column;
    font-size: 18px;
    line-height: 21px;
  }
}

.isoLabel{
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
  background: yellowBasic;
  border-radius: 20px;
  padding: 3px 10px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: navy;

  @media (max-width: 768px){
    margin-top: 16px;
    margin-left: 0;
  }
}

.eyeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.mobileWrapper{
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px){
    height: 760px;
    width: calc(100vw - 120px);
  }

  @media (max-width: 768px){
    height: 510px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 600px){
    height: 250px;
  }
}

.infoWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 53px 10px 0px;

  @media (max-width: 1024px){
    transform: scale(0.75);
  }

  @media (max-width: 768px){
    padding: 59px 0 0px;
    transform: scale(0.5);
    transform-origin: 50% 50%;
  }

  @media (max-width: 600px){
    transform: scale(0.23);
  }
}

.cardsWrapper{
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 1041px;
  flex-shrink: 0;
}

.infoBlock {
  width: 100%;

  &:not(:last-child){
    margin-bottom: 40px;
  }

  &:first-child{
    .mainContent{
      &:hover{
        background: blue;
        color: whiteBasic;
        border: 1px solid blue;
      }
    }
  }

  &:last-child{
    .mainContent {
      &:hover {
        background: navy;
        color: whiteBasic;
        border: 1px solid navy;
      }
    }
  }
}

.mainContent{
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 30px;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: navy;
  background: whiteBasic;
  border: 1px solid navy;
  box-sizing: border-box;
  transition: .3s ease;

  &.mainContentOpen{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
}

.infoBlockInnerWrapper{
  display: flex;
  align-items: stretch;
  margin-top: 20px;
  width: 100%;

  &.infoBlockInnerWrapperVertical{
    flex-direction: column;

    .infoBlockInnerType2{
      &:not(:last-child){
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}

.infoBlockInner{
  background: blue1;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;
  transition: .3s ease;

  &:not(:last-child){
    margin-right: 30px;
  }

  &:hover{
    background: navy;
    color: whiteBasic;

    .description{
      background: red;
    }
  }
}

.description{
  margin-top: 10px;
  width: 100%;
  flex: 1;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: whiteBasic;
  background: navy;
  border-radius: 5px;
  padding: 26px 8px;
  transition: .3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  .text{
    text-align: center;
  }
}

.link{
  color: whiteBasic;

  &:hover{
    color: whiteBasic
  }
}

.infoBlockInnerType2{
  background: blue1;
  border-radius: 10px;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: whiteBasic;
  padding: 20px;

  &.infoBlockInnerType2Open{
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}

.infoBlockInnerType3{
  min-height: 140px;
  background: navy;
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: RobotoItalic, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  color: whiteBasic;
  transition: .3s ease;

  &:not(:last-child){
    margin-right: 30px;
  }

  &:hover{
    background: red;
    color: whiteBasic;

    .infoTitle{
      background: navy;
      color: whiteBasic;
    }
  }
}

.infoTitle{
  width: 100%;
  padding: 5px 8px;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.05em;
  color: grey1;
  background: whiteBasic;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: .3s ease;
}

.scrollWrapperTablet{
  @media (max-width: 1024px){
    margin: 30px 0;
  }
}
