@value whiteBasic from '../../../assets/styles/abstracts/colors.scss';
@value grey1 from '../../../assets/styles/abstracts/colors.scss';
@value grey2 from '../../../assets/styles/abstracts/colors.scss';
@value grey3 from '../../../assets/styles/abstracts/colors.scss';
@value grey4 from '../../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../../assets/styles/abstracts/colors.scss';
@value navy from '../../../assets/styles/abstracts/colors.scss';
@value blue from '../../../assets/styles/abstracts/colors.scss';
@value greyBackground from '../../../assets/styles/abstracts/colors.scss';
@value greyBorder from '../../../assets/styles/abstracts/colors.scss';
@value pink from '../../../assets/styles/abstracts/colors.scss';
@value yellowBasic from '../../../assets/styles/abstracts/colors.scss';
@value blue1 from '../../../assets/styles/abstracts/colors.scss';

.root{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.background{
  width: 100%;
  height: 100%;
  position: absolute;
}

.container{
  width: 100%;
  max-width: 750px;
  position: relative;
  z-index: 1;
  background: whiteBasic;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 768px){
    max-width: calc(100% - 32px);
    max-height: 90vh;
  }
}

.header{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: navy;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 21px;
    padding: 20px 16px;
  }
}

.title{
  display: flex;
  align-items: flex-start;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: navy;
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
}

.acronym{
  margin-left: 5px;
}

.flag{
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(10px);
  margin-right: 20px;
  width: 35px;

  @media (max-width: 768px){
    margin-right: 10px;
  }
}

.flagSmall{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 30px;
}

.subTitle{
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: navy;
  padding-left: 55px;

  @media (max-width: 768px){
    padding-left: 45px;
  }
}

.headerTexts{
  display: flex;
  flex: 1;

  @media (max-width: 768px){
    flex-direction: column;
  }
}

.heading{
  flex-shrink: 10000;
  margin-right: auto;
}

.headingLabel{
  width: fit-content;
  height: fit-content;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: whiteBasic;
  padding: 3px 10px;
  background: pink;
  border-radius: 20px;
  margin-right: 20px;
  margin-top: 10px;

  @media (max-width: 768px){
    margin-right: 0;
    margin-left: 45px;
  }
}

.closeIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-top: 10px;
  cursor: pointer;

  svg{
    transition: .3s ease;
    transform: rotate(-90deg);

    path{
      transition: .3s ease;
    }
  }

  &:hover{
    svg{
      transform: rotate(0deg);

      path{
        fill: navy;
      }
    }
  }
}

.body{
  border-top: 1px solid greyBorder;
  padding: 20px;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: grey1;

  @media (max-width: 768px){
    padding: 20px 16px;
    font-size: 14px;
    line-height: 17px;
  }
}

.definition, .example, .note{
  width: 100%;
  border: 1px solid grey2;
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  white-space: pre-line;

  .title{
    font-family: RobotoBold, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: grey1;
    background: grey5;
    border-radius: 8px 0px 0px 8px;
    width: 90px;
    flex-shrink: 0;
    padding: 10px;

    @media (max-width: 600px){
      border-radius: 8px 8px 0 0;
      width: 100%;
    }
  }

  .content{
    padding: 10px;
    font-family: RobotoLight, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: grey1;
    flex: 1;
    background: whiteBasic;
    border-radius: 0px 8px 8px 0px;

    ul{
      margin: 0;
      padding-left: 25px;
    }

    b{
      font-family: RobotoBold, Arial, sans-serif;
      font-weight: normal;
    }

    @media (max-width: 600px){
      border-radius: 0 0 8px 8px;
      width: 100%;
    }
  }

  &:not(:last-child){
    margin-bottom: 20px;
  }

  @media (max-width: 600px){
    flex-direction: column;
  }
}

.noDefinition, .noExample, .noNote{
  width: 100%;
  border: 1px dashed grey2;
  border-radius: 8px;
  display: flex;
  align-items: stretch;

  .title{
    font-family: RobotoBold, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: grey3;
    background: grey5;
    border-radius: 8px 0px 0px 8px;
    width: 90px;
    flex-shrink: 0;
    padding: 10px;

    @media (max-width: 600px){
      border-radius: 8px 8px 0 0;
      width: 100%;
    }
  }

  .content{
    padding: 10px;
    font-family: RobotoItalic, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: grey3;
    flex: 1;
    background: greyBackground;
    border-radius: 0px 8px 8px 0px;

    @media (max-width: 600px){
      border-radius: 0 0 8px 8px;
      width: 100%;
    }
  }

  &:not(:last-child){
    margin-bottom: 20px;
  }

  @media (max-width: 600px){
    flex-direction: column;
  }
}

.blockLinkedUsage{
  border: 1px solid navy;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;

  .blockHeader{
    background: navy;
  }

  &:not(:last-child){
    margin-bottom: 20px;
  }
}

.blockLinkedNote{
  border: 1px solid grey2;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;

  .blockHeader{
    background: grey2;
  }

  &:not(:last-child){
    margin-bottom: 20px;
  }
}

.blockHeader{
  border-radius: 10px 10px 0px 0px;
  padding: 5px 10px;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: whiteBasic;
}

.blockContent{
  border-radius: 0px 0px 10px 10px;
  padding: 10px;

  .definition, .example, .note{
    &:not(:last-child){
      margin-bottom: 7px;
    }
  }

  .noDefinition, .noExample, .noNote{
    &:not(:last-child){
      margin-bottom: 7px;
    }
  }
}

.blockTitle{
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  font-family: RobotoBold, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: blue;

  .acronym{
    margin-left: 5px;
    font-family: RobotoBold, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: grey1;
    text-decoration-line: underline;
  }

  .underlined{
    text-decoration-line: underline;
    flex-shrink: 10000;
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px){
    flex-direction: column;
    align-items: flex-start;
  }
}

.blockLabel{
  width: fit-content;
  height: fit-content;
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: whiteBasic;
  padding: 3px 10px;
  border-radius: 20px;

  @media (max-width: 768px){
    margin-top: 5px;
  }
}

.noteGeneral{
  background: blue1;
  border-radius: 10px;
  width: 100%;
  font-family: RobotoLight, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: whiteBasic;
  padding: 10px;
  margin-top: 27px;
  white-space: pre-line;
}

.noteGeneralTitle{
  font-family: RobotoMedium, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: greyBackground;
  background: grey1;
  border-radius: 5px;
  padding: 0 10px 3px;
  width: fit-content;
  transform: translateY(calc(-50% - 10px)) translateX(7px);
  margin-bottom: -17px;
}
