@value whiteBasic from '../../assets/styles/abstracts/colors.scss';
@value grey5 from '../../assets/styles/abstracts/colors.scss';
@value navy from '../../assets/styles/abstracts/colors.scss';
@value blue from '../../assets/styles/abstracts/colors.scss';

.arrowLeft,
.arrowRight {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    top: 20px;
    right: 0;
    justify-content: center;
    height: calc(100% - 40px);
    width: 100px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    transition: background 0.3s ease;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    svg {
        path {
            fill: grey5;
            transition: 0.3s ease;
        }
    }

    &:hover {
        background: rgba($color: #ffffff, $alpha: 0.5);
        svg {
            path {
                fill: blue;
            }
        }
    }

    &:active {
        svg {
            path {
                fill: navy;
            }
        }
    }

    @media (max-width: 1200px) {
        width: 60px;
    }

    @media (max-width: 768px) {
        width: 30px;

        &:hover {
            background: none;
        }
        svg {
            height: 60px;
            width: auto;
        }
    }
}

.arrowLeft {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    left: 0;
}

.arrowContent{
    height: calc(100vh - 106px - 110px - 40px);
    position: fixed;
    top: 106px;
    display: flex;
    align-items: center;
}
